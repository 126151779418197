import {useEffect} from "react";
import {Icons} from "@sede-x/shell-ds-react-framework";
import {useColourVariant} from "../../hooks";
import {StatusVariants} from "../../models/enums";

interface StatusIndicatorProps {
  variant: StatusVariants;
  style?: React.CSSProperties;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({variant, style}) => {
  const {colour, setVariant} = useColourVariant(variant);

  useEffect(() => {
    setVariant(variant);
  }, [variant]);

  switch (variant) {
    case StatusVariants.Success:
      return (
        <Icons.CheckCircleSolid
          style={style}
          fill={colour}
          data-testid="status-indicator-success"
        />
      );
    case StatusVariants.Error:
      return (
        <Icons.AddCircleSolid
          style={{...style, transform: "rotate(45deg)"}}
          fill={colour}
          data-testid="status-indicator-error"
        />
      );
    case StatusVariants.Warning:
      return (
        <Icons.AlertCircleSolid
          style={style}
          fill={colour}
          data-testid="status-indicator-warning"
        />
      );
    case StatusVariants.Information:
      return <Icons.InfoCircle style={style} fill={colour} data-testid="status-indicator-info" />;
    default:
      return null;
  }
};

export default StatusIndicator;
