import {addDays, format} from "date-fns";
import {getRefreshTimeInSeconds} from "../../core";
import {IPositionInfo} from "../../models";
import {getDay1DateForCurrentTime} from "../../helpers";
import {ThemeModes} from "../../models/enums/themeType";
import {IAsset} from "../../models/assets";

export interface IDataState {
  asset: string;
  assets: string[];
  assetsMetaData: IAsset[];
  selectedAssets: IAsset[];
  tag: string | undefined;
  data: IPositionInfo;
  hasloadedBefore: boolean;
  loading: boolean;
  errorMessage: string;
  refreshTime: number;
  lastDay1DateUpdatedTo: string;
  unauthenticated: boolean;
  unauthorised: boolean;
  authenticated: boolean;
  authenticating: boolean;
  firstAuthenticated: boolean;
  themeMode: ThemeModes;
}

export const initialState: IDataState = {
  asset: "Spec",
  assets: ["Spec"],
  assetsMetaData: [],
  selectedAssets: [],
  tag: undefined,
  data: {
    day1Date: format(addDays(new Date(), 1), "yyyy-MM-dd"),
    day0Type: "NormalDay",
    day1Type: "NormalDay",
    day2Type: "NormalDay",
    positions: [],
  },
  hasloadedBefore: false,
  loading: false,
  errorMessage: "",
  refreshTime: getRefreshTimeInSeconds(),
  lastDay1DateUpdatedTo: format(getDay1DateForCurrentTime(), "yyyy-MM-dd"),
  unauthenticated: false,
  unauthorised: false,
  authenticated: false,
  authenticating: true,
  firstAuthenticated: false,
  themeMode: ThemeModes.dark,
};
