import styled from "styled-components";
import {useFlags} from "launchdarkly-react-client-sdk";

import "./Maintenance.css";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: #d6433b;
  color: #fff;
`;

const ArticleWrapper = styled.article`
  display: block;
  box-sizing: border-box;
  text-align: center;
  width: 700px;
  padding: 50px;
  margin: 0 auto;
`;

const H1 = styled.h1`
  font-size: 50px;
  font-weight: 100;
  text-align: center;
`;

const SVGWrapper = styled.svg`
  width: 75px;
  margin-top: 1em;
`;

const TextWrapper = styled.div`
  &:hover a {
    text-decoration: none;
  }
`;

const Link = styled.a`
  color: #fff;
  font-weight: bold;
`;

export const MaintenancePage = () => {
  return (
    <Container data-testid="maintenance-page">
      <ArticleWrapper>
        <SVGWrapper xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202.24 202.24">
          <title>Asset 3</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Capa_1" data-name="Capa 1">
              <path
                fill="#fff"
                d="M101.12,0A101.12,101.12,0,1,0,202.24,101.12,101.12,101.12,0,0,0,101.12,0ZM159,148.76H43.28a11.57,11.57,0,0,1-10-17.34L91.09,31.16a11.57,11.57,0,0,1,20.06,0L169,131.43a11.57,11.57,0,0,1-10,17.34Z"
              />
              <path
                fill="#fff"
                d="M101.12,36.93h0L43.27,137.21H159L101.13,36.94Zm0,88.7a7.71,7.71,0,1,1,7.71-7.71A7.71,7.71,0,0,1,101.12,125.63Zm7.71-50.13a7.56,7.56,0,0,1-.11,1.3l-3.8,22.49a3.86,3.86,0,0,1-7.61,0l-3.8-22.49a8,8,0,0,1-.11-1.3,7.71,7.71,0,1,1,15.43,0Z"
              />
            </g>
          </g>
        </SVGWrapper>
        <H1>We&rsquo;ll be back soon!</H1>
        <TextWrapper>
          <p>
            We apologise for the inconvenience, our application is currently undergoing maintenance.
            If you have any questions or need assistance, please contact us at&nbsp;
            <Link href="mailto:LON-YR-Cratos-Support@shell.com">
              LON-YR-Cratos-Support@shell.com
            </Link>
            .
          </p>
          <p>Thank you for your patience and understanding.</p>
          <p>&mdash; The Cratos Team</p>
        </TextWrapper>
      </ArticleWrapper>
    </Container>
  );
};

const Maintenace = ({children}: {children: React.ReactNode}) => {
  const {configureUiMaintenanceMode} = useFlags();

  return configureUiMaintenanceMode ? <MaintenancePage /> : children;
};

export default Maintenace;
