import {Button, IModalProps, Modal, Sizes, Variants, Text} from "@sede-x/shell-ds-react-framework";
import {ReactElement, useEffect} from "react";
import styled from "styled-components";

export interface IConfirmDialogProps extends IModalProps {
  title: string;
  description: string;
  content: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmButtonText: string;
  cancelButtonText: string;
}

export const ConfirmDialog = ({
  open,
  title,
  description,
  content,
  onConfirm,
  onClose,
  onCancel,
  confirmButtonText,
  cancelButtonText,
}: IConfirmDialogProps): ReactElement => {
  useEffect(() => {}, [open]);

  return (
    <Modal
      open={open}
      title={title}
      showFooter={true}
      description={description}
      onClose={onClose}
      footer={
        <Text size={Sizes.Medium}>
          <FlowDiv>
            <div>
              <Button
                id="btnCancel"
                variant={Variants.Outlined}
                onClick={onCancel}
                style={{marginRight: 8}}
              >
                {cancelButtonText}
              </Button>
              <Button
                id="btnConfirm"
                data-testid="ConfirmButton"
                variant={Variants.Filled}
                onClick={onConfirm}
              >
                {confirmButtonText}
              </Button>
            </div>
          </FlowDiv>
        </Text>
      }
    >
      <Text size={Sizes.Medium}>{content}</Text>
    </Modal>
  );
};

const FlowDiv = styled.div`
  flex-flow: row nowrap;
  align-items: center;
  padding: 10px;
`;
