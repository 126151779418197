import React, {createContext, useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AuthenticatedAction, AuthenticatingAction} from "../../store/data/action-creator";
import {AuthenticationService} from "./AuthenticationService";

export type AuthenticationContextType = {
  token: string | undefined;
  email: string | undefined;
  username: string | undefined;
  isAuthenticated: boolean;
  login: () => Promise<void>;
  finishSignIn: () => Promise<void>;
};

const AuthenticationContext = createContext<AuthenticationContextType>({
  token: "",
  email: "",
  username: "",
  isAuthenticated: false,
  login: () => Promise.resolve(),
  finishSignIn: () => Promise.resolve(),
});

export const useAuthentication = () => useContext<AuthenticationContextType>(AuthenticationContext);

type AuthenticationProviderState = {
  isAuthenticated: boolean;
  token: string;
  email: string | undefined;
  username: string | undefined;
};

export const AuthenticationProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  const [authContext, setAuthContext] = useState<AuthenticationProviderState>({
    isAuthenticated: false,
    token: "",
    email: "",
    username: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    // this checks the web pages local state for a user
    // if there is one, then it **shouldn't** do an arbitrary signin
    AuthenticationService.Instance.getUser().then((user) => {
      const isAuthed = user.token !== undefined;
      setAuthContext({
        isAuthenticated: isAuthed,
        token: user.token ?? "",
        email: user.email,
        username: user.username,
      });
      dispatch(AuthenticatedAction(isAuthed));
    });

    AuthenticationService.Instance.addRenewCallback((success, token, email, username) => {
      if (success && token) {
        setAuthContext({isAuthenticated: true, token, email, username});
      } else {
        setAuthContext({isAuthenticated: false, token: "", email: "", username: ""});
      }
    });

    return () => {
      AuthenticationService.Instance.removeRenewCallback();
    };
  }, []);

  const login = () => {
    dispatch(AuthenticatingAction());
    return AuthenticationService.Instance.login();
  };

  const finishSignIn = () => AuthenticationService.Instance.acceptCallback();

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated: authContext.isAuthenticated,
        token: authContext.token,
        email: authContext.email,
        username: authContext.username,
        login,
        finishSignIn,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
