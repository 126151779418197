import React, {createContext, useContext, useEffect, useState} from "react";
import {HubConnectionBuilder} from "@microsoft/signalr";
import {getSignalRHubEndpoint} from "../../core";
import {AuthenticationService} from "../Authentication/AuthenticationService";

interface ISignalRContext {
  onMessageReceived: (messageType: string, callback: (message: any) => void) => void;
}

const defaultSignalRContextValue: ISignalRContext = {
  onMessageReceived: () => {
    console.log("SignalR context not initialized.");
  },
};

const SignalRContext = createContext<ISignalRContext>(defaultSignalRContextValue);

export async function getAccessToken(): Promise<string> {
  const maxRetries = 5;
  const retryInterval = 500;

  const getFromAuth = async (retryCount: number = 0): Promise<string> => {
    const {token} = await AuthenticationService.Instance.getUser();
    if (token) {
      return token;
    }
    if (retryCount < maxRetries) {
      await new Promise((resolve) => setTimeout(resolve, retryInterval));
      return getFromAuth(retryCount + 1);
    }
    // Couldn't get token after 5 retries
    throw new Error("Access token could not be retrieved.");
  };

  return await getFromAuth();
}

export const useSignalR = () => useContext(SignalRContext);

export const SignalRProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
  const [signalRContextValue, setSignalRContextValue] = useState<ISignalRContext>(
    defaultSignalRContextValue
  );

  useEffect(() => {
    let isMounted = true;
    async function startConnection() {
      const url = getSignalRHubEndpoint();
      const token = await getAccessToken();
      const newConnection = new HubConnectionBuilder()
        .withUrl(url, {accessTokenFactory: () => token})
        .withAutomaticReconnect()
        .build();

      try {
        await newConnection.start();
        console.log("SignalR Connected.");
        if (isMounted) {
          setConnection(newConnection);
          setSignalRContextValue({
            onMessageReceived: (messageType: string, callback: (message: any) => void) => {
              newConnection.on(messageType, callback);
            },
          });
        }
      } catch (err) {
        console.log("Error while establishing connection:", err);
      }
    }
    startConnection();

    return () => {
      isMounted = false;
      if (connection) {
        connection.stop();
      }
    };
  }, []);

  return <SignalRContext.Provider value={signalRContextValue}>{children}</SignalRContext.Provider>;
};
