import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import styled from "styled-components";
import {Modal, Variants, Toggle} from "@sede-x/shell-ds-react-framework";
import {ThemeModes} from "../../models/enums/themeType";
import {IApplicationState} from "../../store";
import {SetThemeModeAction} from "../../store/data/action-creator";

type Props = {
  onClose: () => void;
  open: boolean;
};

export const Settings: React.FC<Props> = ({onClose, open}) => {
  const dispatch = useDispatch();
  const themeMode = useSelector((state: IApplicationState) => state.data.themeMode);
  const [theme, setTheme] = useState<ThemeModes>(themeMode);

  useEffect(() => {
    setTheme(themeMode);
  }, [themeMode]);

  return (
    <Modal
      open={open}
      title="Settings"
      description="Change the application settings"
      onClose={() => {
        setTheme(themeMode);
        onClose();
      }}
      actions={[
        {
          label: "Cancel",
          action: () => {
            setTheme(themeMode);
            onClose();
          },
          props: {
            variant: Variants.Outlined,
          },
        },
        {
          label: "Save",
          action: () => {
            dispatch(SetThemeModeAction(theme));
            onClose();
          },
        },
      ]}
    >
      <StyledDiv>
        <StyledSpan>Dark Mode</StyledSpan>
        <Toggle
          data-testid="toggle"
          checked={theme === ThemeModes.light}
          onChange={() => setTheme(theme === ThemeModes.light ? ThemeModes.dark : ThemeModes.light)}
        />
        <StyledSpan>Light Mode</StyledSpan>
      </StyledDiv>
    </Modal>
  );
};

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSpan = styled.span`
  margin: 10px;
  font-weight: 400px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0em;
  font-style: normal;
  margin-top: 0;
  margin-bottom: 0;
`;
