import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "styled-components";

import {Expander, Sizes, TBody, TD, TH, THead, TR, Table} from "@sede-x/shell-ds-react-framework";
import {
  CellContext,
  ExpandedState,
  Getter,
  Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {addDays, isBefore, subDays} from "date-fns";
import {fromZonedTime} from "date-fns-tz";
import {useLocation} from "react-router";
import {useSearchParams} from "react-router-dom";
import {BarChartComponent, LoadingIndicator, PageWrapper} from "../../components";
import {refreshScreen} from "../../components/NavMenu/EnvironmentHeader";
import {getRefreshTimeInSeconds} from "../../core";
import {
  getDay1DateForCurrentTime,
  renderCentred,
  renderLeftAligned,
  renderVolume,
  roundNumber,
} from "../../helpers";
import {useWindowSize} from "../../hooks";
import {Day, IChartData, IPosition, ITableData, ITableDataDetail} from "../../models";
import {IApplicationState} from "../../store";
import {
  FetchMultipleAssetsDataAction,
  UpdateRefreshTimerAction,
} from "../../store/data/action-creator";
import {
  ColouredHeaderUnderline,
  ExpanderLabel,
  GateClosureLine,
  GraphOverlay,
  NowLine,
  PNGateLine,
  ScrollContainer,
  TableOverlay,
} from "../../styles/Position.Styled";
import {compareString, getGateLineIndentHalfHourly} from "../../utils";
import {IAsset} from "../../models/assets";

const THREE_ROWS_ASSETS = 3;
const TWO_ROWS_ASSETS = 2;

const ADJUST_ROW_POSITION = 2;

const OPEN_POSITION = "Open Position";

const NUMBER_COLUMNS_TILL_BLOCK_1 = 0;
const NUMBER_COLUMNS_TILL_BLOCK_2 = 8;
const NUMBER_COLUMNS_TILL_BLOCK_3 = 16;
const NUMBER_COLUMNS_TILL_BLOCK_4 = 24;
const NUMBER_COLUMNS_TILL_BLOCK_5 = 32;
const NUMBER_COLUMNS_TILL_BLOCK_6 = 40;
const NUMBER_COLUMNS_TILL_BLOCK_7 = 48;
const NUMBER_COLUMNS_TILL_BLOCK_8 = 56;
const NUMBER_COLUMNS_TILL_BLOCK_9 = 64;
const NUMBER_COLUMNS_TILL_BLOCK_10 = 72;
const NUMBER_COLUMNS_TILL_BLOCK_11 = 80;
const NUMBER_COLUMNS_TILL_BLOCK_12 = 88;
const NUMBER_COLUMNS_TILL_BLOCK_13 = 96;
const NUMBER_COLUMNS_TILL_BLOCK_14 = 104;
const NUMBER_COLUMNS_TILL_BLOCK_15 = 112;

const TABLE_FIRST_COLUMN_WIDTH = 185;
const TABLE_COLUMN_WIDTH = 90;
const GRAPH_START_POSITION_PIXELS = 185;
const GRAPH_OVERLAY_START_POSITION_PIXELS = 186;

const TABLE_HEADER_HEIGHT = 233;
const NUMBER_TOTAL_ROWS_PER_ASSET = 1;
const TABLE_ROW_HEIGHT = 40;
const FIXED_CHART_HEIGHT = 205;
const NOW_LINE_TOP_DIFF = 70;
const GATE_LINE_HEIGHT_DIFF = 269;
const FOURTY_PERCENT = 0.4;
const SCREEN_HEIGHT = 1200;
const SCROLL_DOWN_DELAY = 1200;

const DIFFERENCE_BETWEEN_CHART_HEIGHT_AND_NOW_LINE = 50;

const NUMBER_PERIODS_IN_HALF_BLOCK = 4;
const NUMBER_PERIODS_IN_HALF_BLOCK_ON_SHORT_DAY = 2;
const NUMBER_PERIODS_IN_HALF_BLOCK_ON_LONG_DAY = 6;
const NUMBER_COLUMNS_TILL_SHORT_DAY_DAY_1_CLOCK_CHANGE = 29;
const NUMBER_COLUMNS_TILL_LONG_DAY_DAY_1_CLOCK_CHANGE = 31;
const NUMBER_COLUMNS_TILL_SHORT_DAY_DAY_2_CLOCK_CHANGE = 77;
const NUMBER_COLUMNS_TILL_LONG_DAY_DAY_2_CLOCK_CHANGE = 79;
const SHORT_DAY_COLUMN_OFFSET = -2;
const LONG_DAY_COLUMN_OFFSET = 2;

const SPEC_ASSET = "Spec";

const renderCellExpander = (row: Row<ITableData>, renderValue: Getter<string>): JSX.Element => (
  <ExpanderLabel
    paddingLeft={`${row.depth * 2.5}rem`}
    data-testid={`${renderValue()}-${row.getIsExpanded() ? "expanded" : "collapsed"}`}
  >
    {row.getCanExpand() ? <Expander row={row}>{renderValue()}</Expander> : renderValue()}
  </ExpanderLabel>
);

const renderPeriodHeader = () => <div>Period</div>;

const getNumberOfBlocks = (
  numberPeriodsInBlock: number,
  isShortDay?: boolean,
  isLongDay?: boolean
) => {
  if (isShortDay) {
    return NUMBER_PERIODS_IN_HALF_BLOCK_ON_SHORT_DAY;
  }
  if (isLongDay) {
    return NUMBER_PERIODS_IN_HALF_BLOCK_ON_LONG_DAY;
  }
  return numberPeriodsInBlock;
};

const PositionManagementMultiple = () => {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const theme = useTheme();
  const ref = useRef<HTMLElement>(null);
  const [searchParams] = useSearchParams();
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const {search: queryString} = useLocation();
  const selectedAssets = useSelector((state: IApplicationState) => state.data.selectedAssets);
  const assetsList = selectedAssets.map((x: IAsset) => x.name);
  const getParameters = (assetsParam: string[]) =>
    assetsParam.length > 0 ? assetsParam : assetsList;
  const [assets, setAssets] = useState(getParameters(searchParams.getAll("Assets")));

  const data = useSelector((state: IApplicationState) => state.data.data);
  const positions = useSelector((state: IApplicationState) =>
    state.data.data ? state.data.data.positions : ([] as IPosition[])
  );
  const isLoading = useSelector((state: IApplicationState) => state.data.loading);
  const hasloadedBefore = useSelector((state: IApplicationState) => state.data.hasloadedBefore);
  const refreshTime = useSelector((state: IApplicationState) => state.data.refreshTime);
  const lastDay1DateUpdatedTo = useSelector(
    (state: IApplicationState) => state.data.lastDay1DateUpdatedTo
  );

  const getBackGroundColor = (row: any, index: number): string | null => {
    const isAssetOrPosition =
      row.type === OPEN_POSITION ||
      assetsList.find((x: string) => row.type.toUpperCase() === x.toUpperCase());

    if (isAssetOrPosition && row[index - 1].value > 0) {
      return theme.series[8];
    }

    if (isAssetOrPosition && row[index - 1].value < 0) {
      return theme.series[4];
    }
    return null;
  };

  const numberExtraRows = useMemo(() => {
    const totalAssets = assets.length === 1 && assets[0] === "All" ? assetsList : assets;
    let numberoFRows = totalAssets.length * NUMBER_TOTAL_ROWS_PER_ASSET;
    if ((assets.length === 1 && assets[0] === "All") || assets.length >= 2) {
      const expansionRow = totalAssets.includes(SPEC_ASSET)
        ? totalAssets.sort(compareString).findIndex((asset) => asset === SPEC_ASSET) +
          ADJUST_ROW_POSITION
        : 0;
      for (let i = 2; i <= totalAssets.length + 1; i += 1) {
        if (expanded.hasOwnProperty(i.toString())) {
          numberoFRows += i === expansionRow ? TWO_ROWS_ASSETS : THREE_ROWS_ASSETS;
        }
      }
    } else if (
      assets.length === 1 &&
      totalAssets.filter((asset: string) => asset !== SPEC_ASSET).includes(assets[0])
    ) {
      numberoFRows += expanded.hasOwnProperty("2") ? THREE_ROWS_ASSETS : 0;
    } else if (assets.length === 1 && assets[0] === SPEC_ASSET) {
      numberoFRows += expanded.hasOwnProperty("2") ? TWO_ROWS_ASSETS : 0;
    }
    return numberoFRows;
  }, [data, positions, expanded]); // one less row for Spec and All

  const screenHeight = size?.height ? size.height : SCREEN_HEIGHT;
  const fixedChartHeight = screenHeight * FOURTY_PERCENT;
  const fixedTableRowsHeight = TABLE_HEADER_HEIGHT + 2 * TABLE_ROW_HEIGHT;
  const extraHeightFromExtraRows = numberExtraRows * TABLE_ROW_HEIGHT;
  const tableHeight = fixedTableRowsHeight + extraHeightFromExtraRows;
  const chartHeightWithoutTable = screenHeight - tableHeight;
  const minFixedChartHeight =
    fixedChartHeight > FIXED_CHART_HEIGHT ? fixedChartHeight : FIXED_CHART_HEIGHT;
  const chartHeight =
    chartHeightWithoutTable < minFixedChartHeight ? minFixedChartHeight : chartHeightWithoutTable;
  const tableOverlayHeight = tableHeight - TABLE_HEADER_HEIGHT;
  const nowLineHeight = chartHeight - DIFFERENCE_BETWEEN_CHART_HEIGHT_AND_NOW_LINE;
  const nowLineTop = tableHeight - NOW_LINE_TOP_DIFF;
  const gateLineHeight = tableHeight + chartHeight - GATE_LINE_HEIGHT_DIFF;

  const [tableWidth, setTableWidth] = useState<number>(0);
  const [nowLineIndent, setNowLineIndent] = useState<number>(GRAPH_START_POSITION_PIXELS);
  const [gateClosureLineIndent, setGateClosureLineIndent] = useState<number>(
    GRAPH_START_POSITION_PIXELS
  );
  const [pnGateLineIndent, setPnGateLineIndent] = useState<number>(GRAPH_START_POSITION_PIXELS);

  useEffect(() => {
    if (
      (assets.length === 1 && assets[0] === "All" && assetsList.length >= 2) ||
      assets.length >= 2
    ) {
      table.resetExpanded();
    } else {
      table.toggleAllRowsExpanded(true);
      setExpanded({"2": true});
    }
  }, [assets]);

  useEffect(() => {
    const newAssets = queryString
      ? queryString.substring(queryString.indexOf("=") + 1).split(",")
      : assetsList;
    setAssets(newAssets);
    if (selectedAssets.length > 0) {
      dispatch(FetchMultipleAssetsDataAction(newAssets, selectedAssets));
    }

    dispatch(UpdateRefreshTimerAction(getRefreshTimeInSeconds()));
  }, [queryString, selectedAssets]);

  const scrollToNow = () => {
    const scrollContainer: HTMLElement | null = document.getElementById(
      "horizontal-scroll-container"
    );
    scrollContainer?.scrollTo({
      left:
        Math.round(nowLineIndent / TABLE_COLUMN_WIDTH) * TABLE_COLUMN_WIDTH -
        TABLE_COLUMN_WIDTH * 7,
      behavior: "smooth",
    });
    scrollContainer?.focus();
  };

  const scrollToBottom = () => {
    const scrollContainer: HTMLElement | null = document.getElementById(
      "horizontal-scroll-container"
    );

    scrollContainer?.scrollTo({
      top: scrollContainer.scrollHeight,
    });
    scrollContainer?.focus();
  };

  useEffect(() => {
    scrollToBottom();
  }, [expanded]);

  const updateMarkerLines = () => {
    if (!positions || positions.length > 0) {
      const {nowTimeHalfHour, gateClosureHalfHour, pnGateHalfHour} = getGateLineIndentHalfHourly(
        positions[0].deliveryStartTime
      );

      setNowLineIndent(
        Math.round(GRAPH_START_POSITION_PIXELS + nowTimeHalfHour * TABLE_COLUMN_WIDTH)
      );
      setGateClosureLineIndent(
        Math.round(GRAPH_START_POSITION_PIXELS + gateClosureHalfHour * TABLE_COLUMN_WIDTH) +
          TABLE_COLUMN_WIDTH / 2
      );
      setPnGateLineIndent(
        Math.round(GRAPH_START_POSITION_PIXELS + pnGateHalfHour * TABLE_COLUMN_WIDTH) +
          TABLE_COLUMN_WIDTH / 2
      );
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(UpdateRefreshTimerAction(refreshTime - 1));

      const day1Date = getDay1DateForCurrentTime();
      const day1D = fromZonedTime(new Date(lastDay1DateUpdatedTo), "Europe/London");
      // end of EFA day refresh
      if (isBefore(day1D, day1Date)) {
        refreshScreen();
      }
      // regular refresh
      if (refreshTime === 0) {
        dispatch(FetchMultipleAssetsDataAction(assets, selectedAssets));
        dispatch(UpdateRefreshTimerAction(getRefreshTimeInSeconds()));
      }
      // update lines every 5 seconds
      if (refreshTime % 5 === 0) {
        updateMarkerLines();
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    updateMarkerLines();
  }, [data, positions]);

  useLayoutEffect(() => {
    const scrollLeftTimer = setTimeout(() => {
      if (ref.current) {
        setTableWidth(ref.current ? ref.current.offsetWidth : 0);
        scrollToNow();
      }
    }, 2);
    const timer = setTimeout(() => {
      scrollToBottom();
    }, SCROLL_DOWN_DELAY);
    return () => {
      clearTimeout(scrollLeftTimer);
      clearTimeout(timer);
    };
  }, [ref.current, tableWidth]);

  function getKeyFromPosition(pivotedObj: any, obj: IPosition) {
    if (!pivotedObj[obj.periodOrder]) {
      pivotedObj[obj.periodOrder] = {
        key: pivotedObj.type + obj.periodOrder,
      };
    }
  }

  function getIntradayFromIntradayAggregation(pivotedObj: ITableData) {
    if (pivotedObj.type === "IntradayAggregation") {
      pivotedObj.type = "ID-Continuous";
    }
  }

  function getHedgeFromTradePosition(pivotedObj: ITableData) {
    if (pivotedObj.type === "TradedPosition") {
      pivotedObj.type = "Other-Hedges";
    }
  }

  const getSubRows = (uniqueTypes: string[], originalData: IPosition[], assetName: string) => {
    const subRows = [] as ITableData[];
    uniqueTypes.forEach((item) => {
      subRows.push(
        originalData
          .filter((f) => f.assetName === assetName)
          .reduce((pivotedObj: any, obj: IPosition) => {
            if (item === obj.type) {
              pivotedObj.type = obj.type;

              if (obj.assetName === "IC_UK" && obj.type === "Forecast") {
                pivotedObj.type = "Nominations";
              }
              // title context correction - make plural
              getHedgeFromTradePosition(pivotedObj);
              getIntradayFromIntradayAggregation(pivotedObj);
              getKeyFromPosition(pivotedObj, obj);
              pivotedObj[obj.periodOrder] = pivotedObj[obj.periodOrder]
                ? {
                    key: pivotedObj.type + obj.periodOrder,
                    value: obj.value,
                    dataType: "volume",
                    period: obj.period,
                    assetName: obj.assetName,
                  }
                : 0;
            }
            return pivotedObj;
          }, {})
      );
    });
    return subRows;
  };

  const transformMultipleAssetDataForTable = (originalData: IPosition[]) => {
    let uniqueTypes = [] as string[];
    const pivotedData = [] as ITableData[];

    const assetList = Array.from(new Set(originalData.map((item: IPosition) => item.assetName)));
    pivotedData.push(
      originalData.reduce((pivotedObj: any, obj: IPosition) => {
        if (obj.type === "Forecast") {
          pivotedObj.type = "Delivery Start";
          pivotedObj[obj.periodOrder] = {
            key: `DeliveryStart${obj.periodOrder}`,
            value: obj.startTimeUk,
            dataType: "time",
          };
        }
        return pivotedObj;
      }, {})
    );

    uniqueTypes = originalData.reduce((uniqueValues: string[], obj: IPosition) => {
      if (!uniqueValues.includes(obj.type)) {
        uniqueValues.push(obj.type);
      }
      return uniqueValues;
    }, []);

    // Grand total
    pivotedData.push(
      originalData.reduce((pivotedObj: any, obj: IPosition) => {
        pivotedObj.type = OPEN_POSITION;
        pivotedObj[obj.periodOrder] = pivotedObj[obj.periodOrder]
          ? {
              key: `OpenPosition${obj.periodOrder}`,
              value: pivotedObj[obj.periodOrder].value + (obj.value ? obj.value : 0),
              dataType: "volume",
            }
          : {
              key: `OpenPosition${obj.periodOrder}`,
              value: obj.value ? obj.value : 0,
              dataType: "volume",
              context: OPEN_POSITION,
            };
        return pivotedObj;
      }, {})
    );

    // Totals for each
    assetList.forEach((a: string) => {
      pivotedData.push(
        originalData
          .filter((f) => f.assetName === a)
          .reduce((pivotedObj: any, obj: IPosition) => {
            pivotedObj.type = a.toUpperCase();
            pivotedObj[obj.periodOrder] = pivotedObj[obj.periodOrder]
              ? {
                  key: `${a}OpenPosition${obj.periodOrder}`,
                  value: pivotedObj[obj.periodOrder].value + (obj.value ? obj.value : 0),
                  dataType: "volume",
                }
              : {
                  key: `${a}OpenPosition${obj.periodOrder}`,
                  value: obj.value ? obj.value : 0,
                  dataType: "volume",
                  context: a + OPEN_POSITION,
                };
            pivotedObj.subRows = getSubRows(uniqueTypes, originalData, a);
            return pivotedObj;
          }, {})
      );
    });
    return pivotedData;
  };

  const transformDataForChart = (originalData: IPosition[]) => {
    const chartData: IChartData[] = originalData.reduce(
      (pivotedObj: IChartData[], obj: IPosition) => {
        const value = obj.value ? obj.value : 0;
        pivotedObj[obj.periodOrder] = {
          openPosition: (obj.value =
            pivotedObj[obj.periodOrder] && pivotedObj[obj.periodOrder].openPosition
              ? roundNumber(pivotedObj[obj.periodOrder].openPosition + value, 1)
              : value),
          period: obj.period,
          key: `${obj.deliveryStartTime}-${obj.periodOrder}`,
        } as IChartData;
        return pivotedObj;
      },
      [] as IChartData[]
    );
    return chartData;
  };

  const getColumnNames = (originalData: IPosition[]) => {
    return originalData.reduce((pivotedObj: any, obj: IPosition) => {
      if (obj.type === "Forecast") {
        pivotedObj[obj.periodOrder] = obj.period ? obj.period : "";
      }
      return pivotedObj;
    }, {});
  };

  const transformedTableData: ITableData[] = useMemo(
    () => positions && transformMultipleAssetDataForTable(positions),
    [data, positions]
  );

  const transformedChartData: IChartData[] = useMemo(
    () => positions && transformDataForChart(positions),
    [data, positions]
  );

  function getMinimumOpenPosition() {
    const min = transformedChartData.reduce((acc, val) => {
      return acc.openPosition < val.openPosition ? acc : val;
    });
    return min.openPosition;
  }

  function getMaximumOpenPosition() {
    const max = transformedChartData.reduce((acc, val) => {
      return acc.openPosition > val.openPosition ? acc : val;
    });
    return max.openPosition;
  }

  const columnNames: string[] = useMemo(() => positions && getColumnNames(positions), [positions]);

  const getCellData = (info: CellContext<ITableData, any>, index: number) =>
    info.row.original[index] as ITableDataDetail;

  const columnHelper = createColumnHelper<ITableData>();

  const OFFSET_AFTER_DAY_1_BLOCK_1 =
    data.day1Type === Day.ShortDay
      ? SHORT_DAY_COLUMN_OFFSET
      : data.day1Type === Day.LongDay
        ? LONG_DAY_COLUMN_OFFSET
        : 0;
  const OFFSET_AFTER_DAY_2_BLOCK_1 =
    data.day1Type === Day.ShortDay || data.day2Type === Day.ShortDay
      ? SHORT_DAY_COLUMN_OFFSET
      : data.day1Type === Day.LongDay || data.day2Type === Day.LongDay
        ? LONG_DAY_COLUMN_OFFSET
        : 0;

  const getBlock = (
    dayNumber: number,
    blockNumber: number,
    periodStartingPoint: number,
    isShortDay?: boolean,
    isLongDay?: boolean
  ) =>
    columnHelper.group({
      id: `day${dayNumber.toString()}block${blockNumber.toString()}`,
      header: () => renderCentred(blockNumber.toString()),
      footer: (props) => props.column.id,
      columns: [
        columnHelper.group({
          id: `day${dayNumber.toString()}block${blockNumber.toString()}hblock1`,
          header: () => renderCentred(`${blockNumber.toString()}A`),
          footer: (props) => props.column.id,
          columns: Array.from(Array(NUMBER_PERIODS_IN_HALF_BLOCK)).map((_, index) =>
            columnHelper.accessor((index + periodStartingPoint).toString(), {
              id: (index + periodStartingPoint).toString(),
              cell: (info) =>
                getCellData(info, index + periodStartingPoint)?.dataType === "time"
                  ? renderCentred(getCellData(info, index + periodStartingPoint)?.value)
                  : renderVolume(getCellData(info, index + periodStartingPoint)?.value?.toString()),
              header: () =>
                renderCentred(
                  columnNames[index + periodStartingPoint]
                    ? columnNames[index + periodStartingPoint]
                    : ""
                ),
              size: TABLE_COLUMN_WIDTH,
              minSize: TABLE_COLUMN_WIDTH,
            })
          ),
        }),
        columnHelper.group({
          id: `day${dayNumber.toString()}block${blockNumber.toString()}hblock2`,
          header: () => renderCentred(`${blockNumber.toString()}B`),
          footer: (props) => props.column.id,
          columns: Array.from(
            Array(getNumberOfBlocks(NUMBER_PERIODS_IN_HALF_BLOCK, isShortDay, isLongDay))
          ).map((x, index) =>
            columnHelper.accessor(
              index.toString() + periodStartingPoint + NUMBER_PERIODS_IN_HALF_BLOCK,
              {
                id: (index + periodStartingPoint + NUMBER_PERIODS_IN_HALF_BLOCK).toString(),
                cell: (info) =>
                  getCellData(info, index + periodStartingPoint + NUMBER_PERIODS_IN_HALF_BLOCK)
                    ?.dataType === "time"
                    ? renderCentred(
                        getCellData(
                          info,
                          index + periodStartingPoint + NUMBER_PERIODS_IN_HALF_BLOCK
                        )?.value
                      )
                    : renderVolume(
                        getCellData(
                          info,
                          index + periodStartingPoint + NUMBER_PERIODS_IN_HALF_BLOCK
                        )?.value?.toString()
                      ),
                header: () =>
                  renderCentred(
                    columnNames[index + periodStartingPoint + NUMBER_PERIODS_IN_HALF_BLOCK]
                      ? columnNames[index + periodStartingPoint + NUMBER_PERIODS_IN_HALF_BLOCK]
                      : ""
                  ),
                size: TABLE_COLUMN_WIDTH,
                minSize: TABLE_COLUMN_WIDTH,
              }
            )
          ),
        }),
      ],
    });

  const columns = React.useMemo(
    () => [
      columnHelper.group({
        header: "EFA Day",
        id: "day0type",
        columns: [
          columnHelper.group({
            header: "Block",
            id: "dayblock0type",
            columns: [
              columnHelper.group({
                header: "Half-Block",
                id: "dayhblock0type",
                columns: [
                  columnHelper.accessor("type", {
                    id: "type 0",
                    cell: ({row, renderValue}) => renderCellExpander(row, renderValue),
                    header: renderPeriodHeader,
                    size: TABLE_FIRST_COLUMN_WIDTH,
                    minSize: TABLE_FIRST_COLUMN_WIDTH,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      columnHelper.group({
        id: "day0",
        header: () =>
          renderLeftAligned(
            new Intl.DateTimeFormat("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }).format(subDays(new Date(data.day1Date), 1))
          ),
        footer: (props) => props.column.id,
        columns: [
          getBlock(0, 4, NUMBER_COLUMNS_TILL_BLOCK_1),
          getBlock(0, 5, NUMBER_COLUMNS_TILL_BLOCK_2),
          getBlock(0, 6, NUMBER_COLUMNS_TILL_BLOCK_3),
        ],
      }),
      columnHelper.group({
        id: "day1am",
        header: () =>
          renderLeftAligned(
            new Intl.DateTimeFormat("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }).format(new Date(data.day1Date))
          ),
        footer: (props) => props.column.id,
        columns: [
          getBlock(
            1,
            1,
            NUMBER_COLUMNS_TILL_BLOCK_4,
            data.day1Type === Day.ShortDay,
            data.day1Type === Day.LongDay
          ),
          getBlock(1, 2, NUMBER_COLUMNS_TILL_BLOCK_5 + OFFSET_AFTER_DAY_1_BLOCK_1),
          getBlock(1, 3, NUMBER_COLUMNS_TILL_BLOCK_6 + OFFSET_AFTER_DAY_1_BLOCK_1),
        ],
      }),
      columnHelper.group({
        id: "day1pm",
        header: () =>
          renderLeftAligned(
            new Intl.DateTimeFormat("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }).format(new Date(data.day1Date))
          ),
        footer: (props) => props.column.id,
        columns: [
          getBlock(1, 4, NUMBER_COLUMNS_TILL_BLOCK_7 + OFFSET_AFTER_DAY_1_BLOCK_1),
          getBlock(1, 5, NUMBER_COLUMNS_TILL_BLOCK_8 + OFFSET_AFTER_DAY_1_BLOCK_1),
          getBlock(1, 6, NUMBER_COLUMNS_TILL_BLOCK_9 + OFFSET_AFTER_DAY_1_BLOCK_1),
        ],
      }),
      columnHelper.group({
        id: "day2am",
        header: () =>
          renderLeftAligned(
            new Intl.DateTimeFormat("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }).format(addDays(new Date(data.day1Date), 1))
          ),
        footer: (props) => props.column.id,
        columns: [
          getBlock(
            2,
            1,
            NUMBER_COLUMNS_TILL_BLOCK_10 + OFFSET_AFTER_DAY_1_BLOCK_1,
            data.day2Type === Day.ShortDay,
            data.day2Type === Day.LongDay
          ),
          getBlock(2, 2, NUMBER_COLUMNS_TILL_BLOCK_11 + OFFSET_AFTER_DAY_2_BLOCK_1),
          getBlock(2, 3, NUMBER_COLUMNS_TILL_BLOCK_12 + OFFSET_AFTER_DAY_2_BLOCK_1),
        ],
      }),
      columnHelper.group({
        id: "day2pm",
        header: () =>
          renderLeftAligned(
            new Intl.DateTimeFormat("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }).format(addDays(new Date(data.day1Date), 1))
          ),
        footer: (props) => props.column.id,
        columns: [
          getBlock(2, 4, NUMBER_COLUMNS_TILL_BLOCK_13 + OFFSET_AFTER_DAY_2_BLOCK_1),
          getBlock(2, 5, NUMBER_COLUMNS_TILL_BLOCK_14 + OFFSET_AFTER_DAY_2_BLOCK_1),
          getBlock(2, 6, NUMBER_COLUMNS_TILL_BLOCK_15 + OFFSET_AFTER_DAY_2_BLOCK_1),
        ],
      }),
    ],
    [data]
  );

  const table = useReactTable<ITableData>({
    data: transformedTableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row: ITableData) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
  });

  const requiresBorder = (index: number) => {
    if (
      !(
        data.day1Type === Day.ShortDay ||
        data.day2Type === Day.ShortDay ||
        data.day1Type === Day.LongDay ||
        data.day2Type === Day.LongDay
      )
    ) {
      return index % 4 === 0;
    }

    if (data.day1Type === Day.ShortDay) {
      return index < NUMBER_COLUMNS_TILL_SHORT_DAY_DAY_1_CLOCK_CHANGE
        ? index % 4 === 0
        : (index + SHORT_DAY_COLUMN_OFFSET) % 4 === 0;
    }
    if (data.day2Type === Day.ShortDay) {
      return index < NUMBER_COLUMNS_TILL_SHORT_DAY_DAY_2_CLOCK_CHANGE
        ? index % 4 === 0
        : (index + SHORT_DAY_COLUMN_OFFSET) % 4 === 0;
    }
    if (data.day1Type === Day.LongDay) {
      return index < NUMBER_COLUMNS_TILL_LONG_DAY_DAY_1_CLOCK_CHANGE
        ? index % 4 === 0
        : (index + LONG_DAY_COLUMN_OFFSET) % 4 === 0;
    }
    if (data.day2Type === Day.LongDay) {
      return index < NUMBER_COLUMNS_TILL_LONG_DAY_DAY_2_CLOCK_CHANGE
        ? index % 4 === 0
        : (index + LONG_DAY_COLUMN_OFFSET) % 4 === 0;
    }
  };

  return (
    <PageWrapper pageTitle="Position Management" data-testid="PositionManagement">
      {isLoading && !hasloadedBefore ? (
        <LoadingIndicator />
      ) : (
        positions &&
        positions.length > 0 && (
          <ScrollContainer id="horizontal-scroll-container" tabIndex={-1}>
            <TableOverlay
              tableStart={TABLE_FIRST_COLUMN_WIDTH}
              height={tableOverlayHeight}
              right={nowLineIndent}
            />
            <GraphOverlay
              graphStart={GRAPH_OVERLAY_START_POSITION_PIXELS}
              height={nowLineHeight}
              right={nowLineIndent}
              top={nowLineTop}
            />
            <NowLine id="now-line" left={nowLineIndent} height={nowLineHeight} top={nowLineTop} />
            <GateClosureLine
              id="gate-closure-line"
              left={gateClosureLineIndent}
              height={gateLineHeight}
            />
            <PNGateLine id="pn-gate-line" left={pnGateLineIndent} height={gateLineHeight} />
            <Table size={Sizes.Medium} onScroll={() => {}}>
              <THead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TR key={headerGroup.id} data-testid="table-header-row">
                    {headerGroup.headers.map((header, index) => (
                      <TH
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          position: index === 0 ? "sticky" : "relative",
                          backgroundColor: index === 0 ? theme.background.raised : "",
                          textAlign: "left",
                          zIndex: index === 0 ? 2 : 0,
                          left: 0,
                          minWidth: header.column.columnDef?.minSize,
                          width: header.column.columnDef?.size,
                          borderRight: `${theme.border.subtle} 1px solid`,
                          height: header.depth === 2 || header.depth === 3 ? 34 : 40,
                          paddingTop: 0,
                        }}
                      >
                        {header.depth === 1 && header.index !== 0 && (
                          <ColouredHeaderUnderline index={header.index} />
                        )}
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </TH>
                    ))}
                  </TR>
                ))}
              </THead>
              <TBody ref={ref}>
                {table.getRowModel().rows.map((row) => {
                  if (
                    !(
                      row.original.type === "Forecast" &&
                      (row.original[0] as ITableDataDetail).assetName === "Spec"
                    )
                  ) {
                    return (
                      <TR key={row.id} data-testid="table-row">
                        {row.getVisibleCells().map((cell, index) => (
                          <TD
                            key={cell.id}
                            style={{
                              position: index === 0 ? "sticky" : "relative",
                              backgroundColor:
                                index === 0
                                  ? theme.background.raised
                                  : getBackGroundColor(cell.row.original, index),
                              zIndex: index === 0 ? 2 : 0,
                              height: 40,
                              paddingTop: 0,
                              paddingBottom: 0,
                              left: 0,
                              fontWeight:
                                cell.row.original.type === OPEN_POSITION ||
                                assetsList.find(
                                  (x: string) =>
                                    cell.row.original.type.toUpperCase() === x.toUpperCase()
                                )
                                  ? "bold"
                                  : "normal",
                              borderRight: requiresBorder(index)
                                ? `${theme.border.subtle} 1px solid`
                                : "",
                            }}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TD>
                        ))}
                      </TR>
                    );
                  }
                  return null;
                })}
              </TBody>
            </Table>
            <BarChartComponent
              data={transformedChartData}
              height={chartHeight}
              width={tableWidth}
              minimum={getMinimumOpenPosition()}
              maximum={getMaximumOpenPosition()}
            />
          </ScrollContainer>
        )
      )}
    </PageWrapper>
  );
};

export default PositionManagementMultiple;
