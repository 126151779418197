import React from "react";
import {LoadingIndicator, PageWrapper} from "../../components";

import {PageHeadings} from "../../models";

export const Authenticating: React.FC = () => {
  return (
    <PageWrapper pageTitle={PageHeadings.Authenticating} data-testid="Authenticating">
      <LoadingIndicator />
    </PageWrapper>
  );
};
