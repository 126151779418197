import {ToastContainer, ToastOptions, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export enum ToastType {
  Success = "Success",
  Error = "Error",
  Info = "Info",
}

export enum ToastLength {
  Unlimited = "Unlimited",
  Long = "Long",
  Short = "Short",
}

const red = "#DD1D21";
const green = "#008557";
const blue = "#4B78AA";

export const sendToast = (message: string, type: ToastType, length?: ToastLength) => {
  const toastLength =
    length === ToastLength.Unlimited ? false : length === ToastLength.Long ? 20000 : 5000;
  const options: ToastOptions = {
    position: "bottom-right",
    autoClose: toastLength,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style:
      type === ToastType.Success
        ? {backgroundColor: green, color: "white"}
        : type === ToastType.Error
          ? {backgroundColor: red, color: "white"}
          : {backgroundColor: blue, color: "white"},
  };
  return (
    <>
      <ToastContainer limit={0} />
      {type === ToastType.Success && toast.success(message, options)}
      {type === ToastType.Error && toast.error(message, options)}
      {type === ToastType.Info && toast.info(message, options)}
    </>
  );
};
