import styled from "styled-components";

interface IPageWrapperProps {
  pageTitle: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const PageStyled = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  z-index: 1;
  height: 100%;
`;

const PageWrapper: React.FC<IPageWrapperProps> = ({children, pageTitle, ...rest}) => (
  <PageStyled {...rest}>{children}</PageStyled>
);

export default PageWrapper;
