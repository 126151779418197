import {
  Modal,
  Sizes,
  Variants,
  Text,
  RadioButton,
  IModalProps,
} from "@sede-x/shell-ds-react-framework";
import {ChangeEvent, ReactElement, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {FetchDataAction, SelectAssetAction} from "../../store/data/action-creator";
import {IApplicationState} from "../../store";

interface IProps extends IModalProps {
  initialValue: string;
}

export const SelectAsset = ({onClose, open, initialValue}: IProps): ReactElement => {
  const dispatch = useDispatch();
  const [asset, setAsset] = useState(initialValue);
  const assetsList = useSelector((state: IApplicationState) => state.data.selectedAssets).map(
    (x) => x.name
  );

  useEffect(() => {
    setAsset(initialValue);
  }, [open, initialValue]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAsset(e.target.name);
  };

  const onSave = () => {
    if (asset !== initialValue) {
      dispatch(SelectAssetAction(asset));
      dispatch(FetchDataAction(asset));
    }
    onClose();
  };

  return (
    <Modal
      open={open}
      title="Select Asset"
      description="Choose the asset to display data for"
      onClose={() => {
        onClose();
        if (onClose) onClose();
      }}
      actions={[
        {
          label: "Cancel",
          action: () => {
            onClose();
          },
          props: {
            id: "btnCancel",
            variant: Variants.Outlined,
          },
        },
        {
          label: "Save",
          props: {
            id: "btnSave",
          },
          action: () => {
            onSave();
          },
        },
      ]}
    >
      <Text size={Sizes.Medium}>
        {assetsList.map((x, i) => (
          <div>
            <StyledRadioButton
              id={`radioButton${i}`}
              label={x}
              checked={asset === x}
              name={x}
              onChange={onChange}
              value={x}
            />
          </div>
        ))}
      </Text>
    </Modal>
  );
};

const StyledRadioButton = styled(RadioButton)`
  margin: 10px;
`;
