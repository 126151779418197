import {NumericFormat} from "react-number-format";
import styled from "styled-components";

const RightAlignedDiv = styled.div`
  text-align: right;
  margin-right: 14px;
`;

export const renderVolume = (value: string | undefined) => {
  return (
    <RightAlignedDiv>
      <NumericFormat
        displayType="text"
        value={Number(value)}
        fixedDecimalScale
        decimalScale={1}
        thousandSeparator=","
      />
    </RightAlignedDiv>
  );
};

const CentredDiv = styled.div`
  text-align: center;
`;

export const renderCentred = (str: string | number | undefined) => <CentredDiv>{str}</CentredDiv>;

const LeftAlignedSpan = styled.span`
  text-align: left;
  padding-left: 10px;
`;

export const renderLeftAligned = (str: string) => <LeftAlignedSpan>{str}</LeftAlignedSpan>;
