import React, {useEffect} from "react";
import {useNavigate} from "react-router";
import {useAuthentication} from "./AuthenticationContext";
import {LoadingIndicator, PageWrapper} from "../../components";
import {PageHeadings} from "../../models";
import {routes} from "../../routes";

interface ISignInCallback {
  returnUrl: string;
}

export const SignInCallback: React.FC<ISignInCallback> = ({returnUrl}) => {
  const auth = useAuthentication();
  const navigate = useNavigate();

  useEffect(() => {
    auth
      .finishSignIn()
      .then(() => navigate(returnUrl))
      .catch(() => navigate(routes.unauthorised()));
  }, []);

  return (
    <PageWrapper pageTitle={PageHeadings.SignInCallback} data-testid="SignInCallback">
      <LoadingIndicator />
    </PageWrapper>
  );
};
