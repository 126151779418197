import {ofType} from "redux-observable";
import {catchError, filter, switchMap, withLatestFrom} from "rxjs/operators";
import {of} from "rxjs";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  ResetToInitialStateAction,
} from "./action-creator";
import {DataActionEnum, IFetchDataAction} from ".";
import {DataAPIClient} from "../../core";
import {IApplicationState} from "../index";
import {ToastType, sendToast} from "../../helpers/toasts";

export const fetchDataEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(DataActionEnum.Fetch_Data),
    withLatestFrom(state$),
    filter(([action, state]: [IFetchDataAction, IApplicationState]) => {
      if (state.data.authenticating) return false;

      return true;
    }),

    switchMap(async ([action, state]: [IFetchDataAction, IApplicationState]) => {
      const response = await DataAPIClient.get(`UIGridofData?assetName=${action.asset}`);
      const data = response.data;
      return FetchDataSuccessAction(data);
    }),
    catchError((x: any) => {
      let error = "";
      const errorMessageReceived = x.message;
      if (x.response && x.response.status && x.response.status === 500) {
        error = "Error on server";
      } else if (
        x.response &&
        x.response.status &&
        (x.response.status === 401 || x.response.status === 403)
      ) {
        return of(ResetToInitialStateAction(x.response.status));
      } else if (errorMessageReceived === "Bad Request") {
        error = "The API query to get data has been called with the wrong parameters";
      } else if (errorMessageReceived === "Not Found") {
        error = "The API query to get the data cannot be found";
      } else {
        error = errorMessageReceived;
      }
      return of(FetchDataFailureAction(error), sendToast(error, ToastType.Error));
    })
  );
