import {useEffect, useState} from "react";
import {useTheme} from "styled-components";
import {StatusVariants} from "../../models/enums";

interface UseColourVariantReturn {
  colour: string;
  setVariant: (variant: StatusVariants) => void;
}

const useColourVariant = (initialValue: StatusVariants): UseColourVariantReturn => {
  const theme = useTheme();
  const SUCCESS_COLOUR = `${theme.additional.border.positive} !important`;
  const ERROR_COLOUR = `${theme.additional.border.negative} !important`;
  const WARNING_COLOUR = `${theme.additional.border.warning} !important`;
  const INFO_COLOUR = `${theme.additional.border.information} !important`;

  const [variant, setVariant] = useState<StatusVariants>(initialValue);
  const [colour, setColour] = useState<string>(SUCCESS_COLOUR);

  useEffect(() => {
    switch (variant) {
      case StatusVariants.Error:
        setColour(ERROR_COLOUR);
        break;
      case StatusVariants.Warning:
        setColour(WARNING_COLOUR);
        break;
      case StatusVariants.Information:
        setColour(INFO_COLOUR);
        break;
      case StatusVariants.Success:
      default:
        setColour(SUCCESS_COLOUR);
        break;
    }
  }, [variant]);

  return {colour, setVariant};
};

export default useColourVariant;
