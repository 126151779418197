import {
  Icons,
  Label,
  Menu,
  MenuItem,
  SubMenu,
  NavBar,
  Text,
  Variants,
  Avatar,
  Sizes,
} from "@sede-x/shell-ds-react-framework";
import {useCallback, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {useFlags} from "launchdarkly-react-client-sdk";
import {routes} from "../../routes";
import {PageHeadings} from "../../models";
import {IApplicationState} from "../../store";
import {useAuthentication} from "../../pages/Authentication";
import {SelectAsset, Settings} from "..";
import {SelectAssets} from "../SelectAssets";
import {EnvironmentHeader} from "./EnvironmentHeader";
import {compareString} from "../../utils";
import {StyledButton} from "../../styles";
import HealthStatusLink from "../HealthStatus";
import RefreshData from "../RefreshData";

const excludeRefreshForPages = ["Help", "Support"];
const excludeSelectionForPages = ["Help", "Support", "grid"];

export const NavMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const {email, username} = useAuthentication();
  const {multipleAssets} = useFlags();

  const asset = useSelector((state: IApplicationState) => state.data.asset);
  const assetsMetaData = useSelector((state: IApplicationState) => state.data.selectedAssets);
  let assetsList: string[] = assetsMetaData.map((x) => x.name);
  const [assets, setAssets] = useState([""]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    assetsList = assetsMetaData.map((x) => x.name);
    if (assetsList.length > 0) {
      setReady(true);
    }
    const params = searchParams.get("Assets")?.split(",") ?? [];
    const assignedAssets = params.some((selectedAsset) => assetsList.includes(selectedAsset))
      ? params
      : assetsList;
    setAssets(assignedAssets);
  }, [searchParams, assetsMetaData]);

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showSettingsPopup, setShowSettingsPopup] = useState<boolean>(false);
  const positionManagementPath = ["/position", "/signin-callback", "/", "/PositionManagement"];
  // track which pages are selected so the menu can display them as selected
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(
    positionManagementPath.some((name) => location.pathname.includes(name)) &&
      !location.pathname.includes("grid")
      ? [multipleAssets ? routes.positionWithAssets(["All"]) : routes.position()]
      : [location.pathname]
  );

  useEffect(() => {
    setDefaultSelectedKeys(
      positionManagementPath.some((name) => location.pathname.includes(name)) &&
        !location.pathname.includes("grid")
        ? [multipleAssets ? routes.positionWithAssets(["All"]) : routes.position()]
        : [location.pathname]
    );
  }, [multipleAssets]);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const closeSettingsPopup = useCallback(() => {
    setShowSettingsPopup(false);
  }, []);

  const getAssetsMenuLabel = () => {
    if (multipleAssets) {
      const multipleAssetLabel =
        [...assets].sort(compareString).join(",") === [...assetsList].sort(compareString).join(",")
          ? "(All)"
          : "(Multiple)";
      const singleAssetLabel = assets[0] === "All" ? "(All)" : assets[0].toUpperCase();
      return assets.length > 1 ? multipleAssetLabel : singleAssetLabel;
    }
    return asset.toUpperCase();
  };

  const MainMenu = (
    <MenuSplitterDiv>
      <NavDiv>
        <NavHeaderText>
          <NavHeaderTopText size="xsmall">Shell Trading</NavHeaderTopText>
          <NavHeaderBottomText size="small">Cratos</NavHeaderBottomText>
        </NavHeaderText>
        <Menu
          mode="horizontal"
          style={{width: 300, zIndex: 2000}}
          selectedKeys={defaultSelectedKeys}
          onClick={({key}) => {
            setDefaultSelectedKeys([key]);
            navigate(key);
          }}
          defaultSelectedKeys={defaultSelectedKeys}
        >
          <MenuItem key={multipleAssets ? routes.positionWithAssets(["All"]) : routes.position()}>
            {PageHeadings.PositionManagement}
          </MenuItem>
          {multipleAssets && <MenuItem key={routes.grid()}>{PageHeadings.Portfolio}</MenuItem>}
        </Menu>
      </NavDiv>
      <RefreshData
        hidden={excludeRefreshForPages.some((name) => location.pathname.includes(name))}
        assets={assets}
      />
      <SelectAsset onClose={closePopup} open={showPopup} initialValue={asset} />
      {ready && (
        <SelectAssets
          onClose={closePopup}
          open={multipleAssets && showPopup}
          initialValues={assets.length === 1 && assets[0] === "All" ? assetsList : assets}
        />
      )}
      <Settings onClose={closeSettingsPopup} open={showSettingsPopup} />
      <RightMenu>
        <HealthStatusLink />
        <AppNameWrapper>
          <StyledButton
            data-testid="btnSelectAsset"
            variant={Variants.Outlined}
            onClick={openPopup}
            icon={<Icons.ChevronDown />}
            iconPosition="right"
            disabled={excludeSelectionForPages.some((name) => location.pathname.includes(name))}
          >
            <TextProduct data-testid="assetsMenuLabel">{ready && getAssetsMenuLabel()}</TextProduct>
          </StyledButton>
        </AppNameWrapper>
        <EnvironmentHeader />
      </RightMenu>
    </MenuSplitterDiv>
  );

  const userInitials = username ? username?.substring(2, 4).toUpperCase() : "";

  const rightMenu = (
    <RightMenuDiv>
      <Menu
        style={{width: 70}}
        mode="vertical"
        selectable={false}
        onClick={({key}) => {
          setDefaultSelectedKeys([]);
          if (key !== "Settings") {
            navigate(key);
          }
        }}
        defaultSelectedKeys={[]}
      >
        <SubMenuStyled
          title={<Avatar abbreviation={userInitials} size={Sizes.Small} />}
          key="SmallMenu"
          style={{width: 180}}
          data-testid="smallMenu"
        >
          <TextStyled size="small">{email}</TextStyled>
          <MenuItem key="Support">Support</MenuItem>
          <MenuItem
            key="Settings"
            data-testid="btnSettings"
            onClick={() => {
              setShowSettingsPopup(true);
            }}
          >
            Settings
          </MenuItem>
          <MenuItem key="Help">Help</MenuItem>
        </SubMenuStyled>
      </Menu>
    </RightMenuDiv>
  );

  return (
    <NavBarStyled>
      {MainMenu}
      {rightMenu}
    </NavBarStyled>
  );
};

const MenuSplitterDiv = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  font-size: 12px;
`;

const NavDiv = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const NavBarStyled = styled(NavBar)`
  margin-bottom: 15px;
`;

export const NavHeaderText = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 120px;
`;

export const NavHeaderTopText = styled(Label)`
  font-weight: 700;
`;

export const NavHeaderBottomText = styled(Text)`
  font-weight: 700;
`;

const RightMenu = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-right: 2px;
`;

const AppNameWrapper = styled.div`
  margin-top: 0px;
  margin-right: 5px;
`;

const TextProduct = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  width: 150px;
  text-align: center;
  padding-right: 3px;
  color: ${({theme}) => theme.text.onSurface.strong};
`;

const RightMenuDiv = styled.div`
  margin-top: 6px;
  margin-right: 15px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 45px;
`;

const SubMenuStyled = styled(SubMenu)`
  .submenu-chevron {
    margin-top: 5px;
  }
  .shell-menu-submenu .shell-menu-submenu-title {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const TextStyled = styled(Text)`
  font-size: 12px;
  color: ${({theme}) => theme.text.onSurface.subtle};
  height: 50px;
  line-height: 50px;
  padding-top: 5px;
  text-align: center;
`;
