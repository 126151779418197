import React, {useState} from "react";
import {Text, Sizes, Button, Divider, Pecten, Variants} from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";
import {
  LoadingIndicator,
  NavHeaderBottomText,
  NavHeaderText,
  NavHeaderTopText,
  PageWrapper,
} from "../../components";
import {PageHeadings} from "../../models";
import {AuthenticationService} from "./AuthenticationService";
import image from "../../images/unauthorised.svg";

export const Unauthorised: React.FC = () => {
  const [signin, setSignin] = useState(false);

  return (
    <PageWrapper
      pageTitle={PageHeadings.Unauthorised}
      data-testid="Unauthorised"
      style={{overflowY: "auto", height: "100vh", marginRight: "0px"}}
    >
      <UnauthorisedPageWrapper>
        <CratosLogo>
          <StyledPecten />
          <NavHeaderText>
            <NavHeaderTopText size="xsmall">Shell Trading</NavHeaderTopText>
            <NavHeaderBottomText size="small">Cratos</NavHeaderBottomText>
          </NavHeaderText>
        </CratosLogo>
        <ImageDiv height={226} src={image} alt="" />
        <CenteredText gutter>
          Sorry your login is not set up with the relevant permissions for you to view this page.
          <br />
          <br />
          To request access, please follow these steps in order:
          <br />
          <ol type="1">
            <StyledList>
              Click
              <ColouredLink
                href="https://docs.dep.shell/latest/register/"
                target="_blank"
                rel="noopener noreferrer"
              >
                this link
              </ColouredLink>
              which will add your login address to the system.
            </StyledList>
            <StyledList>
              Click
              <ColouredLink href="mailto:GX-LON-YR-CRATOS-SUPPORT@shell.com?subject=Access to Cratos">
                this email link
              </ColouredLink>
              to email the Cratos team, citing the reason for needing access.
            </StyledList>
          </ol>
          <br />
          <Divider />
          <br />
          If you&apos;re sure you should have access already, then please press the button below to
          log in.
        </CenteredText>
        <ButtonContainer>
          {signin ? (
            <LoadingIndicator />
          ) : (
            <Button
              variant={Variants.Outlined}
              onClick={() => {
                AuthenticationService.Instance.login();
                setSignin(true);
              }}
            >
              Login
            </Button>
          )}
        </ButtonContainer>
      </UnauthorisedPageWrapper>
    </PageWrapper>
  );
};

const ImageDiv = styled.img`
  height: 226px;
  margin-top: 30px;
  margin-bottom: 25px;
`;

const UnauthorisedPageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;

const CratosLogo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  margin-top: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 150px;
  margin-bottom: 20px;
`;

const CenteredText = styled(Text)`
  text-align: center;
  height: 180px;
`;

const ColouredLink = styled.a`
  color: white;
  padding-left: 5px;
  padding-right: 5px;
`;

const StyledList = styled.li`
  text-align: left;
  line-height: 35px;
`;

const StyledPecten = styled(Pecten)`
  margin-left: 40px;
  margin-right: 10px;
`;
