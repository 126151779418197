import {useTheme} from "styled-components";
import {Ref, forwardRef, memo, useCallback, useEffect, useMemo, useState} from "react";
import {Expander, Sizes, TBody, TD, TH, THead, TR, Table} from "@sede-x/shell-ds-react-framework";
import {
  CellContext,
  ExpandedState,
  Getter,
  Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {addDays, subDays} from "date-fns";
import {Day, DayType, ITableData, ITableDataDetail} from "../../models";
import {ColouredHeaderUnderline, ExpanderLabel} from "../../styles";
import {renderCentred, renderLeftAligned, renderVolume} from "../../helpers";

interface IPosition {
  assetName: string;
  deliveryStartTime: string;
  period: string;
  periodOrder: number;
  startTimeUk: string;
  timestamp: string;
  type: string;
  value: number | null;
}

interface IPositionGridProps {
  updateExtraRows: (extraRows: number) => void;
  isHourly?: boolean;
  data: {
    day1Date: string;
    day0Type: DayType;
    day1Type: DayType;
    day2Type: DayType;
    positions: IPosition[];
  };
}

const getColumnNames = (originalData: IPosition[]) => {
  return originalData.reduce((pivotedObj: any, obj: IPosition) => {
    if (obj.type === "Forecast") {
      pivotedObj[obj.periodOrder] = obj.period ? obj.period : "";
    }
    return pivotedObj;
  }, {});
};

const renderCellExpander = (row: Row<ITableData>, renderValue: Getter<string>): JSX.Element => (
  <ExpanderLabel
    paddingLeft={`${row.depth * 2.5}rem`}
    data-testid={`${renderValue()}-${row.getIsExpanded() ? "expanded" : "collapsed"}`}
  >
    {row.getCanExpand() ? <Expander row={row}>{renderValue()}</Expander> : renderValue()}
  </ExpanderLabel>
);

const renderPeriodHeader = () => <div>Period</div>;

const TABLE_FIRST_COLUMN_WIDTH = 175;
const TABLE_COLUMN_WIDTH = 90;
const NUMBER_PERIODS_IN_TWO_BLOCK = 2;
const NUMBER_PERIODS_IN_FOUR_BLOCK = 4;

const NUMBER_PERIODS_IN_HALF_BLOCK_ON_SHORT_DAY = 2;
const NUMBER_PERIODS_IN_HALF_BLOCK_ON_LONG_DAY = 6;
const SHORT_DAY_COLUMN_OFFSET = -1;
const LONG_DAY_COLUMN_OFFSET = 1;

const OPEN_POSITION = "Open Position";

const NUMBER_COLUMNS_TILL_BLOCK_1 = 0;
const NUMBER_COLUMNS_TILL_BLOCK_2 = 4;
const NUMBER_COLUMNS_TILL_BLOCK_3 = 8;
const NUMBER_COLUMNS_TILL_BLOCK_4 = 12;
const NUMBER_COLUMNS_TILL_BLOCK_5 = 16;
const NUMBER_COLUMNS_TILL_BLOCK_6 = 20;
const NUMBER_COLUMNS_TILL_BLOCK_7 = 24;
const NUMBER_COLUMNS_TILL_BLOCK_8 = 28;
const NUMBER_COLUMNS_TILL_BLOCK_9 = 32;
const NUMBER_COLUMNS_TILL_BLOCK_10 = 36;
const NUMBER_COLUMNS_TILL_BLOCK_11 = 40;
const NUMBER_COLUMNS_TILL_BLOCK_12 = 44;
const NUMBER_COLUMNS_TILL_BLOCK_13 = 48;
const NUMBER_COLUMNS_TILL_BLOCK_14 = 52;
const NUMBER_COLUMNS_TILL_BLOCK_15 = 56;

const NUMBER_COLUMNS_TILL_SHORT_DAY_DAY_1_CLOCK_CHANGE = 29;
const NUMBER_COLUMNS_TILL_LONG_DAY_DAY_1_CLOCK_CHANGE = 31;
const NUMBER_COLUMNS_TILL_SHORT_DAY_DAY_2_CLOCK_CHANGE = 77;
const NUMBER_COLUMNS_TILL_LONG_DAY_DAY_2_CLOCK_CHANGE = 79;

const NUMBER_TOTAL_ROWS_PER_ASSET = 1;
const THREE_ROWS_ASSETS = 3;

const getCellData = (info: CellContext<ITableData, any>, index: number) =>
  info.row.original[index] as ITableDataDetail;

const getNumberOfBlocks = (
  numberPeriodsInBlock: number,
  isShortDay?: boolean,
  isLongDay?: boolean
) => {
  if (isShortDay) {
    return NUMBER_PERIODS_IN_HALF_BLOCK_ON_SHORT_DAY;
  }
  if (isLongDay) {
    return NUMBER_PERIODS_IN_HALF_BLOCK_ON_LONG_DAY;
  }
  return numberPeriodsInBlock;
};

const getOffSetAfterDay1Block1 = (day1Type: DayType) => {
  if (day1Type === Day.ShortDay) {
    return SHORT_DAY_COLUMN_OFFSET;
  }
  if (day1Type === Day.LongDay) {
    return LONG_DAY_COLUMN_OFFSET;
  }
  return 0;
};

const getOffSetAfterDay2Block1 = (day1Type: DayType, day2Type: DayType) => {
  if (day1Type === Day.ShortDay || day2Type === Day.ShortDay) {
    return SHORT_DAY_COLUMN_OFFSET;
  }
  if (day1Type === Day.LongDay || day2Type === Day.LongDay) {
    return LONG_DAY_COLUMN_OFFSET;
  }
  return 0;
};

const PositionGrid = forwardRef(
  ({data, isHourly, updateExtraRows}: IPositionGridProps, ref: Ref<HTMLElement>) => {
    const [expanded, setExpanded] = useState<ExpandedState>({});
    const columnHelper = createColumnHelper<ITableData>();
    const theme = useTheme();
    const Assets = ["IC-BE"];

    const getBackGroundColor = (row: any, index: number): string | null => {
      const isAssetOrPosition =
        row.type === OPEN_POSITION ||
        Assets.find((x: string) => row.type.toUpperCase() === x.toUpperCase());

      if (isAssetOrPosition && row[index - 1].value > 0) {
        return theme.series[8];
      }

      if (isAssetOrPosition && row[index - 1].value < 0) {
        return theme.series[4];
      }
      return null;
    };

    useEffect(() => {
      if (expanded.hasOwnProperty("2")) {
        updateExtraRows(NUMBER_TOTAL_ROWS_PER_ASSET + THREE_ROWS_ASSETS);
      } else {
        updateExtraRows(NUMBER_TOTAL_ROWS_PER_ASSET);
      }
    }, [expanded]);

    const requiresBorder = (index: number) => {
      const numberOfColumns = isHourly ? NUMBER_PERIODS_IN_TWO_BLOCK : NUMBER_PERIODS_IN_FOUR_BLOCK;
      if (
        !(
          data.day1Type === Day.ShortDay ||
          data.day2Type === Day.ShortDay ||
          data.day1Type === Day.LongDay ||
          data.day2Type === Day.LongDay
        )
      ) {
        return index % numberOfColumns === 0;
      }

      if (data.day1Type === Day.ShortDay) {
        return index < NUMBER_COLUMNS_TILL_SHORT_DAY_DAY_1_CLOCK_CHANGE
          ? index % numberOfColumns === 0
          : (index + SHORT_DAY_COLUMN_OFFSET) % numberOfColumns === 0;
      }
      if (data.day2Type === Day.ShortDay) {
        return index < NUMBER_COLUMNS_TILL_SHORT_DAY_DAY_2_CLOCK_CHANGE
          ? index % numberOfColumns === 0
          : (index + SHORT_DAY_COLUMN_OFFSET) % numberOfColumns === 0;
      }
      if (data.day1Type === Day.LongDay) {
        return index < NUMBER_COLUMNS_TILL_LONG_DAY_DAY_1_CLOCK_CHANGE
          ? index % numberOfColumns === 0
          : (index + LONG_DAY_COLUMN_OFFSET) % numberOfColumns === 0;
      }
      if (data.day2Type === Day.LongDay) {
        return index < NUMBER_COLUMNS_TILL_LONG_DAY_DAY_2_CLOCK_CHANGE
          ? index % numberOfColumns === 0
          : (index + LONG_DAY_COLUMN_OFFSET) % numberOfColumns === 0;
      }
    };

    const columnNames: string[] = useMemo(
      () => data.positions && getColumnNames(data.positions),
      [data.positions]
    );

    const getBlock = (
      dayNumber: number,
      blockNumber: number,
      periodStartingPoint: number,
      isShortDay?: boolean,
      isLongDay?: boolean
    ) =>
      columnHelper.group({
        id: `day${dayNumber.toString()}block${blockNumber.toString()}`,
        header: () => renderCentred(blockNumber.toString()),
        footer: (props) => props.column.id,
        columns: [
          columnHelper.group({
            id: `day${dayNumber.toString()}block${blockNumber.toString()}hblock1`,
            header: () => renderCentred(`${blockNumber.toString()}A`),
            footer: (props) => props.column.id,
            columns: Array.from(Array(NUMBER_PERIODS_IN_TWO_BLOCK)).map((x, index) =>
              columnHelper.accessor((index + periodStartingPoint).toString(), {
                id: (index + periodStartingPoint).toString(),
                cell: (info) =>
                  getCellData(info, index + periodStartingPoint)?.dataType === "time"
                    ? renderCentred(getCellData(info, index + periodStartingPoint)?.value)
                    : renderVolume(
                        getCellData(info, index + periodStartingPoint)?.value?.toString()
                      ),
                header: () =>
                  renderCentred(
                    columnNames[index + periodStartingPoint]
                      ? columnNames[index + periodStartingPoint]
                      : ""
                  ),
                size: TABLE_COLUMN_WIDTH,
                minSize: TABLE_COLUMN_WIDTH,
              })
            ),
          }),
          columnHelper.group({
            id: `day${dayNumber.toString()}block${blockNumber.toString()}hblock2`,
            header: () => renderCentred(`${blockNumber.toString()}B`),
            footer: (props) => props.column.id,
            columns: Array.from(
              Array(getNumberOfBlocks(NUMBER_PERIODS_IN_TWO_BLOCK, isShortDay, isLongDay))
            ).map((x, index) =>
              columnHelper.accessor(
                index.toString() + periodStartingPoint + NUMBER_PERIODS_IN_TWO_BLOCK,
                {
                  id: (index + periodStartingPoint + NUMBER_PERIODS_IN_TWO_BLOCK).toString(),
                  cell: (info) =>
                    getCellData(info, index + periodStartingPoint + NUMBER_PERIODS_IN_TWO_BLOCK)
                      ?.dataType === "time"
                      ? renderCentred(
                          getCellData(
                            info,
                            index + periodStartingPoint + NUMBER_PERIODS_IN_TWO_BLOCK
                          )?.value
                        )
                      : renderVolume(
                          getCellData(
                            info,
                            index + periodStartingPoint + NUMBER_PERIODS_IN_TWO_BLOCK
                          )?.value?.toString()
                        ),
                  header: () =>
                    renderCentred(
                      columnNames[index + periodStartingPoint + NUMBER_PERIODS_IN_TWO_BLOCK]
                        ? columnNames[index + periodStartingPoint + NUMBER_PERIODS_IN_TWO_BLOCK]
                        : ""
                    ),
                  size: TABLE_COLUMN_WIDTH,
                  minSize: TABLE_COLUMN_WIDTH,
                }
              )
            ),
          }),
        ],
      });

    const columns = useMemo(
      () => [
        columnHelper.group({
          header: "EFA Day",
          id: "day0type",
          columns: [
            columnHelper.group({
              header: "Block",
              id: "dayblock0type",
              columns: [
                columnHelper.group({
                  header: "Half-Block",
                  id: "dayhblock0type",
                  columns: [
                    columnHelper.accessor("type", {
                      id: "type 0",
                      cell: ({row, renderValue}) => renderCellExpander(row, renderValue),
                      header: renderPeriodHeader,
                      size: TABLE_FIRST_COLUMN_WIDTH,
                      minSize: TABLE_FIRST_COLUMN_WIDTH,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        columnHelper.group({
          id: "day0pm",
          header: () =>
            renderLeftAligned(
              new Intl.DateTimeFormat("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }).format(subDays(new Date(data.day1Date), 1))
            ),
          footer: (props) => props.column.id,
          columns: [
            getBlock(0, 4, NUMBER_COLUMNS_TILL_BLOCK_1),
            getBlock(0, 5, NUMBER_COLUMNS_TILL_BLOCK_2),
            getBlock(0, 6, NUMBER_COLUMNS_TILL_BLOCK_3),
          ],
        }),
        columnHelper.group({
          id: "day1am",
          header: () =>
            renderLeftAligned(
              new Intl.DateTimeFormat("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }).format(new Date(data.day1Date))
            ),
          footer: (props) => props.column.id,
          columns: [
            getBlock(
              1,
              1,
              NUMBER_COLUMNS_TILL_BLOCK_4,
              data.day1Type === Day.ShortDay,
              data.day1Type === Day.LongDay
            ),
            getBlock(1, 2, NUMBER_COLUMNS_TILL_BLOCK_5 + getOffSetAfterDay1Block1(data.day1Type)),
            getBlock(1, 3, NUMBER_COLUMNS_TILL_BLOCK_6 + getOffSetAfterDay1Block1(data.day1Type)),
          ],
        }),
        columnHelper.group({
          id: "day1pm",
          header: () =>
            renderLeftAligned(
              new Intl.DateTimeFormat("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }).format(new Date(data.day1Date))
            ),
          footer: (props) => props.column.id,
          columns: [
            getBlock(1, 4, NUMBER_COLUMNS_TILL_BLOCK_7 + getOffSetAfterDay1Block1(data.day1Type)),
            getBlock(1, 5, NUMBER_COLUMNS_TILL_BLOCK_8 + getOffSetAfterDay1Block1(data.day1Type)),
            getBlock(1, 6, NUMBER_COLUMNS_TILL_BLOCK_9 + getOffSetAfterDay1Block1(data.day1Type)),
          ],
        }),
        columnHelper.group({
          id: "day2am",
          header: () =>
            renderLeftAligned(
              new Intl.DateTimeFormat("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }).format(addDays(new Date(data.day1Date), 1))
            ),
          footer: (props) => props.column.id,
          columns: [
            getBlock(
              2,
              1,
              NUMBER_COLUMNS_TILL_BLOCK_10 + getOffSetAfterDay1Block1(data.day1Type),
              data.day2Type === Day.ShortDay,
              data.day2Type === Day.LongDay
            ),
            getBlock(
              2,
              2,
              NUMBER_COLUMNS_TILL_BLOCK_11 + getOffSetAfterDay2Block1(data.day1Type, data.day2Type)
            ),
            getBlock(
              2,
              3,
              NUMBER_COLUMNS_TILL_BLOCK_12 + getOffSetAfterDay2Block1(data.day1Type, data.day2Type)
            ),
          ],
        }),
        columnHelper.group({
          id: "day2pm",
          header: () =>
            renderLeftAligned(
              new Intl.DateTimeFormat("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }).format(addDays(new Date(data.day1Date), 1))
            ),
          footer: (props) => props.column.id,
          columns: [
            getBlock(
              2,
              4,
              NUMBER_COLUMNS_TILL_BLOCK_13 + getOffSetAfterDay2Block1(data.day1Type, data.day2Type)
            ),
            getBlock(
              2,
              5,
              NUMBER_COLUMNS_TILL_BLOCK_14 + getOffSetAfterDay2Block1(data.day1Type, data.day2Type)
            ),
            getBlock(
              2,
              6,
              NUMBER_COLUMNS_TILL_BLOCK_15 + getOffSetAfterDay2Block1(data.day1Type, data.day2Type)
            ),
          ],
        }),
      ],
      []
    );

    function getKeyFromPosition(pivotedObj: any, obj: IPosition) {
      if (!pivotedObj[obj.periodOrder]) {
        pivotedObj[obj.periodOrder] = {
          key: pivotedObj.type + obj.periodOrder,
        };
      }
    }

    function getIntradayFromIntradayAggregation(pivotedObj: ITableData) {
      if (pivotedObj.type === "IntradayAggregation") {
        pivotedObj.type = "ID-Continuous";
      }
    }

    function getHedgeFromTradePosition(pivotedObj: ITableData) {
      if (pivotedObj.type === "TradedPosition") {
        pivotedObj.type = "Other-Hedges";
      }
    }

    const getSubRows = (uniqueTypes: string[], originalData: IPosition[], assetName: string) => {
      const subRows = [] as ITableData[];
      uniqueTypes.forEach((item) => {
        subRows.push(
          originalData
            .filter((f) => f.assetName === assetName)
            .reduce((pivotedObj: any, obj: IPosition) => {
              if (item === obj.type) {
                pivotedObj.type = obj.type;

                if (obj.assetName === "IC_UK" && pivotedObj.type === "Forecast") {
                  pivotedObj.type = "Nominations";
                }

                getHedgeFromTradePosition(pivotedObj);
                getIntradayFromIntradayAggregation(pivotedObj);
                getKeyFromPosition(pivotedObj, obj);

                pivotedObj[obj.periodOrder] = pivotedObj[obj.periodOrder]
                  ? {
                      key: pivotedObj.type + obj.periodOrder,
                      value: obj.value,
                      dataType: "volume",
                      period: obj.period,
                      assetName: obj.assetName,
                    }
                  : 0;
              }
              return pivotedObj;
            }, {})
        );
      });
      return subRows;
    };

    const transformAssetDataForTable = useCallback(
      (originalData: IPosition[]) => {
        const pivotedData = [] as ITableData[];

        pivotedData.push(
          originalData.reduce((pivotedObj: any, obj: IPosition) => {
            if (obj.type === "Forecast") {
              pivotedObj.type = "Delivery Start";
              pivotedObj[obj.periodOrder] = {
                key: `DeliveryStart${obj.periodOrder}`,
                value: obj.startTimeUk,
                dataType: "time",
              };
            }
            return pivotedObj;
          }, {})
        );

        // Grand total
        pivotedData.push(
          originalData.reduce((pivotedObj: any, obj: IPosition) => {
            pivotedObj.type = OPEN_POSITION;
            pivotedObj[obj.periodOrder] = pivotedObj[obj.periodOrder]
              ? {
                  key: `OpenPosition${obj.periodOrder}`,
                  value: pivotedObj[obj.periodOrder].value + (obj.value ? obj.value : 0),
                  dataType: "volume",
                }
              : {
                  key: `OpenPosition${obj.periodOrder}`,
                  value: obj.value ? obj.value : 0,
                  dataType: "volume",
                  context: OPEN_POSITION,
                };
            return pivotedObj;
          }, {})
        );

        let uniqueTypes = [] as string[];
        const assetList = Array.from(
          new Set(originalData.map((item: IPosition) => item.assetName))
        );

        uniqueTypes = originalData.reduce((uniqueValues: string[], obj: IPosition) => {
          if (!uniqueValues.includes(obj.type)) {
            uniqueValues.push(obj.type);
          }
          return uniqueValues;
        }, []);

        // Totals for each
        assetList.forEach((a: string) => {
          pivotedData.push(
            originalData
              .filter((f) => f.assetName === a)
              .reduce((pivotedObj: any, obj: IPosition) => {
                pivotedObj.type = a.toUpperCase();
                pivotedObj[obj.periodOrder] = pivotedObj[obj.periodOrder]
                  ? {
                      key: `${a}OpenPosition${obj.periodOrder}`,
                      value: pivotedObj[obj.periodOrder].value + (obj.value ? obj.value : 0),
                      dataType: "volume",
                    }
                  : {
                      key: `${a}OpenPosition${obj.periodOrder}`,
                      value: obj.value ? obj.value : 0,
                      dataType: "volume",
                      context: a + OPEN_POSITION,
                    };
                pivotedObj.subRows = getSubRows(uniqueTypes, originalData, a);
                return pivotedObj;
              }, {})
          );
        });
        return pivotedData;
      },
      [data]
    );

    const transformedTableData: ITableData[] = useMemo(
      () => data && transformAssetDataForTable(data.positions),
      [data]
    );

    const table = useReactTable<ITableData>({
      data: transformedTableData,
      columns,
      getCoreRowModel: getCoreRowModel(),
      state: {
        expanded,
      },
      onExpandedChange: setExpanded,
      getSubRows: (row: ITableData) => row.subRows,
      getExpandedRowModel: getExpandedRowModel(),
    });

    useEffect(() => {
      table.toggleAllRowsExpanded(true);
      setExpanded({"2": true});
    }, []);

    return (
      <div data-testid="position-grid">
        <Table size={Sizes.Medium}>
          <THead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TR key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <TH
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      position: index === 0 ? "sticky" : "relative",
                      backgroundColor: index === 0 ? theme.background.raised : "",
                      textAlign: "left",
                      zIndex: index === 0 ? 2 : 0,
                      left: 0,
                      minWidth: header.column.columnDef?.minSize,
                      width: header.column.columnDef?.size,
                      borderRight: `${theme.border.subtle} 1px solid`,
                      height: header.depth === 2 || header.depth === 3 ? 34 : 40,
                      paddingTop: 0,
                    }}
                  >
                    {header.depth === 1 && header.index !== 0 && (
                      <ColouredHeaderUnderline index={header.index} />
                    )}
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TH>
                ))}
              </TR>
            ))}
          </THead>
          <TBody ref={ref}>
            {table.getRowModel().rows.map((row) => (
              <TR key={row.id} data-testid="table-row">
                {row.getVisibleCells().map((cell, index) => (
                  <TD
                    key={cell.id}
                    style={{
                      position: index === 0 ? "sticky" : "relative",
                      backgroundColor:
                        index === 0
                          ? theme.background.raised
                          : getBackGroundColor(cell.row.original, index),
                      zIndex: index === 0 ? 2 : 0,
                      height: 40,
                      paddingTop: 0,
                      paddingBottom: 0,
                      left: 0,
                      fontWeight:
                        cell.row.original.type === OPEN_POSITION ||
                        Assets.find(
                          (x: string) => cell.row.original.type.toUpperCase() === x.toUpperCase()
                        )
                          ? "bold"
                          : "normal",
                      borderRight: requiresBorder(index) ? `${theme.border.subtle} 1px solid` : "",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TD>
                ))}
              </TR>
            ))}
          </TBody>
        </Table>
      </div>
    );
  }
);

PositionGrid.displayName = "PositionGrid";
PositionGrid.defaultProps = {
  isHourly: false,
};

export default memo(PositionGrid);
