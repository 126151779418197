import {memo, useCallback} from "react";
import styled from "styled-components";
import {useFlags} from "launchdarkly-react-client-sdk";
import {useDispatch, useSelector} from "react-redux";
import {Loading, Variants, Text} from "@sede-x/shell-ds-react-framework";
import {StyledButton} from "../../styles";
import {IApplicationState} from "../../store";
import {
  FetchDataAction,
  FetchMultipleAssetsDataAction,
  UpdateRefreshTimerAction,
} from "../../store/data/action-creator";
import {getRefreshTimeInSeconds} from "../../core";
import {useAuthentication} from "../../pages/Authentication";

const RefreshContainer = styled.div<{hidden: boolean}>`
  flex-grow: 1;
  display: ${({hidden}) => (hidden ? "none" : "flex")};
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-left: 15px;
  margin-right: 15px;
  font-size: 12px;
`;

type RefreshDataProps = {
  hidden: boolean;
  assets: string[];
};

export const RefreshDataTimeText: React.FC = () => {
  const refreshTime = useSelector((state: IApplicationState) => state.data.refreshTime);
  return (
    <StyledText>
      {refreshTime} second{`${refreshTime !== 1 ? "s" : ""} till auto refresh`}
    </StyledText>
  );
};

const RefreshData: React.FC<RefreshDataProps> = ({hidden, assets}) => {
  const dispatch = useDispatch();
  const {multipleAssets} = useFlags();
  const asset = useSelector((state: IApplicationState) => state.data.asset);
  const selectedAssets = useSelector((state: IApplicationState) => state.data.selectedAssets);
  const isLoading = useSelector((state: IApplicationState) => state.data.loading);
  const hasloadedBefore = useSelector((state: IApplicationState) => state.data.hasloadedBefore);
  const showLoading = isLoading && hasloadedBefore;

  const forceRefresh = useCallback(() => {
    if (multipleAssets) {
      dispatch(FetchMultipleAssetsDataAction(assets, selectedAssets));
    } else {
      dispatch(FetchDataAction(asset));
    }
    dispatch(UpdateRefreshTimerAction(getRefreshTimeInSeconds()));
  }, [multipleAssets, asset, assets]);

  return (
    <RefreshContainer hidden={hidden}>
      <StyledButton
        id="btnRefresh"
        variant={Variants.Outlined}
        onClick={forceRefresh}
        icon={showLoading && <Loading type="default" />}
        iconPosition="right"
      >
        Refresh Data
      </StyledButton>
      <RefreshDataTimeText />
    </RefreshContainer>
  );
};

export default memo(RefreshData);
