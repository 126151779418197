import {ofType} from "redux-observable";
import {catchError, filter, switchMap, withLatestFrom, map} from "rxjs/operators";
import {of, from} from "rxjs";
import {
  FetchMultipleAssetsDataFailureAction,
  FetchMultipleAssetsDataSuccessAction,
  ResetToInitialStateAction,
} from "./action-creator";
import {DataActionEnum, IFetchMultipleAssetsDataAction} from ".";
import {DataAPIClient} from "../../core";
import {IApplicationState} from "../index";
import {ToastType, sendToast} from "../../helpers/toasts";

export const fetchMultipleAssetsDataEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(DataActionEnum.Fetch_Multiple_Assets_Data),
    withLatestFrom(state$),
    filter(([action, state]: [IFetchMultipleAssetsDataAction, IApplicationState]) => {
      if (state.data.authenticating) {
        return false;
      }
      return true;
    }),

    switchMap(([action, state]: [IFetchMultipleAssetsDataAction, IApplicationState]) => {
      const assets =
        action.assets.length === 1 && action.assets[0] === "All"
          ? action.selectedAssets.map((x) => x.name)
          : action.assets;
      return from(
        DataAPIClient.get(`UIGridofData/multipleassets?assetNames=${assets.join("&assetNames=")}`)
      ).pipe(
        map((response) => FetchMultipleAssetsDataSuccessAction(response.data)),
        catchError((x: any) => {
          let error = "";
          const errorMessageReceived = x.message;
          if (x.response && x.response.status && x.response.status === 500) {
            error = "Error on server";
          } else if (
            x.response &&
            x.response.status &&
            (x.response.status === 401 || x.response.status === 403)
          ) {
            return of(ResetToInitialStateAction(x.response.status));
          } else if (errorMessageReceived === "Bad Request") {
            error = "The API query to get data has been called with the wrong parameters";
          } else if (errorMessageReceived === "Not Found") {
            error = "The API query to get the data cannot be found";
          } else {
            error = errorMessageReceived;
          }
          return of(FetchMultipleAssetsDataFailureAction(error), sendToast(error, ToastType.Error));
        })
      );
    })
  );
