import {useState, useEffect} from "react";
import {HealthCheckResult, HealthCheckService} from "../../helpers";
import {StatusVariants} from "../../models/enums";
import {getAPIBaseUrl} from "../../core";

enum HealthStatus {
  Healthy = "Healthy",
  Unhealthy = "Unhealthy",
  Degraded = "Degraded",
}

type EntriesResult = {
  id: number;
  name: string;
  status: HealthStatus;
  description: string;
  duration: string;
  tags: string[];
};

type HealthCheckResponse = {
  id: number;
  status: HealthStatus;
  onStateFrom: string;
  lastExecuted: string;
  uri: string;
  name: string;
  discoveryService: null;
  entries: EntriesResult[];
  history: string[];
};

const failedResponse = {
  services: [],
  healthStatus: StatusVariants.Error,
  description: "An error occurred while fetching health check data",
};

const getResponse = (data: HealthCheckResponse[]): HealthCheckResult => {
  const services: HealthCheckService[] = [];
  const healthStatus = data.reduce((acc, curr) => {
    if (curr.status === HealthStatus.Unhealthy) {
      return StatusVariants.Error;
    }
    if (curr.status === HealthStatus.Degraded) {
      return StatusVariants.Warning;
    }
    return acc;
  }, StatusVariants.Success);

  if (healthStatus === StatusVariants.Success) {
    return {
      services,
      healthStatus,
      description: "All services are operational",
    };
  }

  for (let i = 0; i < data.length; i += 1) {
    if (data[i].status === HealthStatus.Unhealthy || data[i].status === HealthStatus.Degraded) {
      services.push({
        name: data[i].name,
        healthStatus:
          data[i].status === HealthStatus.Unhealthy ? StatusVariants.Error : StatusVariants.Warning,
        lastExecuted: data[i].lastExecuted,
        description:
          data[i].entries.find(
            (system) =>
              system.status === HealthStatus.Unhealthy || system.status === HealthStatus.Degraded
          )?.description ?? "",
      });
    }
  }
  return {
    services,
    healthStatus,
    description: "Service interruptions detected",
  };
};
export const checkServiceHealth = (url: string): Promise<HealthCheckResult> => {
  return fetch(url, {
    method: "GET",
  })
    .then((response: Response) => {
      if (response.status !== 200) {
        return failedResponse;
      }
      return response.json();
    })
    .then((data) => {
      return getResponse(data);
    })
    .catch(() => {
      return failedResponse;
    });
};

const useHealthCheck = (refreshInterval = 15000): HealthCheckResult => {
  const url = `${getAPIBaseUrl()}healthchecks-api`;
  const [response, setResponse] = useState<HealthCheckResult>({
    services: [],
    healthStatus: StatusVariants.Success,
    description: "All services are operational",
  });

  useEffect(() => {
    checkServiceHealth(url).then((res) => {
      setResponse(res);
    });
    const interval = setInterval(() => {
      checkServiceHealth(url).then((res) => {
        setResponse(res);
      });
    }, refreshInterval);

    return () => {
      clearInterval(interval);
    };
  }, [refreshInterval]);

  return response;
};

export default useHealthCheck;
