import {Action} from "redux";
import {IPositionInfo} from "../../models";
import {ThemeModes} from "../../models/enums/themeType";
import {IAsset} from "../../models/assets";

export type {IDataState} from "./state";

export enum DataActionEnum {
  Fetch_Data = "@@Data/Fetch_Data",
  Fetch_Data_Success = "@@Data/Fetch_Data_Success",
  Fetch_Data_Failure = "@@Data/Fetch_Data_Failure",
  Fetch_Multiple_Assets_Data = "@@Data/Fetch_Multiple_Assets_Data",
  Fetch_Multiple_Assets_Data_Success = "@@Data/Fetch_Multiple_Assets_Data_Success",
  Fetch_Multiple_Assets_Data_Failure = "@@Data/Fetch_Multiple_Assets_Data_Failure",
  Select_Asset = "@@Data/Select_Asset",
  Select_Assets = "@@Data/Select_Assets",
  Select_Tag = "@@Data/Select_Tag",
  Update_Refresh_Timer = "@@Data/Update_Refresh_Timer",
  Set_Day1_Date_Updated_To = "@@Data/Set_Day1_Date_Updated_To",
  Reset_To_Initial_State = "@@Data/Reset_To_Initial_State",
  Authenticated = "@@Authenticated",
  Authenticating = "@@Authenticating",
  FirstAuthenticated = "@@FirstAuthenticated",
  Post_Audit = "@@Data/Post_Audit",
  Post_Audit_Success = "@@Data/Post_Audit_Success",
  Post_Audit_Failure = "@@Data/Post_Audit_Failure",
  Set_Theme_Mode = "@@Set_Theme_Mode",
  Fetch_Asset_Data = "@@Data/Fetch_Meta_Data",
  Fetch_Asset_Data_Success = "@@Data/Fetch_Meta_Data_Success",
  Fetch_Asset_Data_Failure = "@@Data/Fetch_Meta_Data_Failure",
  Add_Asset_Data = "@@Data/Add_Asset_Data",
  Remove_Asset_Data = "@@Data/Remove_Asset_Data",
}

export interface IFetchDataAction extends Action<typeof DataActionEnum.Fetch_Data> {
  asset: string;
}

export interface IFetchDataSuccessAction extends Action<typeof DataActionEnum.Fetch_Data_Success> {
  data: IPositionInfo;
}

export interface IFetchDataFailureAction extends Action<typeof DataActionEnum.Fetch_Data_Failure> {
  errorMessage?: string;
}

export interface IFetchMultipleAssetsDataAction
  extends Action<typeof DataActionEnum.Fetch_Multiple_Assets_Data> {
  assets: string[];
  selectedAssets: IAsset[];
}

export interface IFetchMultipleAssetsDataSuccessAction
  extends Action<typeof DataActionEnum.Fetch_Multiple_Assets_Data_Success> {
  data: IPositionInfo;
}

export interface IFetchMultipleAssetsDataFailureAction
  extends Action<typeof DataActionEnum.Fetch_Multiple_Assets_Data_Failure> {
  errorMessage?: string;
}

export interface ISelectAssetAction extends Action<typeof DataActionEnum.Select_Asset> {
  asset: string;
}

export interface ISelectAssetsAction extends Action<typeof DataActionEnum.Select_Assets> {
  assets: string[];
}

export interface ISelectTagAction extends Action<typeof DataActionEnum.Select_Tag> {
  tag: string;
}

export interface IUpdateRefreshTimerAction
  extends Action<typeof DataActionEnum.Update_Refresh_Timer> {
  seconds: number;
}

export interface ISetDay1DateUpdatedToAction
  extends Action<typeof DataActionEnum.Set_Day1_Date_Updated_To> {
  day1Date: string;
}

export interface IResetToInitialStateAction
  extends Action<typeof DataActionEnum.Reset_To_Initial_State> {
  httpStatus: number;
}

export interface IAuthenticatedAction extends Action<typeof DataActionEnum.Authenticated> {
  authenticated: boolean;
}
export interface IAuthenticatingAction extends Action<typeof DataActionEnum.Authenticating> {}

export interface IFirstAuthenticatedAction
  extends Action<typeof DataActionEnum.FirstAuthenticated> {
  firstAuthenticated: boolean;
}

export interface IPostAuditAction extends Action<typeof DataActionEnum.Post_Audit> {
  category: string;
  action: string;
}

export interface IPostAuditSuccessAction extends Action<typeof DataActionEnum.Post_Audit_Success> {}

export interface IPostAuditFailureAction extends Action<typeof DataActionEnum.Post_Audit_Failure> {
  errorMessage?: string;
}

export interface ISetThemeModeAction extends Action<typeof DataActionEnum.Set_Theme_Mode> {
  themeMode: ThemeModes;
}

export interface IFetchAssetDataAction extends Action<typeof DataActionEnum.Fetch_Asset_Data> {}

export interface IFetchAssetDataSuccessAction
  extends Action<typeof DataActionEnum.Fetch_Asset_Data_Success> {
  assetsMetadata: IAsset[];
}

export interface IFetchAssetDataFailureAction
  extends Action<typeof DataActionEnum.Fetch_Asset_Data_Failure> {
  errorMessage?: string;
}

export interface IAddAssetDataAction extends Action<typeof DataActionEnum.Add_Asset_Data> {
  asset: IAsset;
}

export interface IRemoveAssetDataAction extends Action<typeof DataActionEnum.Remove_Asset_Data> {
  asset: IAsset;
}

export type DataActions =
  | IFetchDataAction
  | IFetchDataSuccessAction
  | IFetchDataFailureAction
  | IFetchMultipleAssetsDataAction
  | IFetchMultipleAssetsDataSuccessAction
  | IFetchMultipleAssetsDataFailureAction
  | ISelectAssetAction
  | ISelectAssetsAction
  | ISelectTagAction
  | IUpdateRefreshTimerAction
  | ISetDay1DateUpdatedToAction
  | IResetToInitialStateAction
  | IAuthenticatedAction
  | IAuthenticatingAction
  | IFirstAuthenticatedAction
  | IPostAuditAction
  | IPostAuditSuccessAction
  | IPostAuditFailureAction
  | ISetThemeModeAction
  | IFetchAssetDataAction
  | IFetchAssetDataSuccessAction
  | IFetchAssetDataFailureAction
  | IAddAssetDataAction
  | IRemoveAssetDataAction;
