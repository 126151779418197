import {applyMiddleware, createStore} from "redux";
import {createEpicMiddleware} from "redux-observable";
import {composeWithDevTools} from "redux-devtools-extension";
import {rootEpic, rootReducer} from "./store";

const epicMiddleware = createEpicMiddleware();
const store = createStore(
  rootReducer,
  undefined,
  composeWithDevTools(applyMiddleware(epicMiddleware))
);
epicMiddleware.run(rootEpic);
export {store};
