import {ofType} from "redux-observable";
import {catchError, filter, map, switchMap, withLatestFrom} from "rxjs/operators";
import {of} from "rxjs";
import {DataActionEnum, IPostAuditAction} from ".";
import {PostAuditFailureAction, PostAuditSuccessAction} from "./action-creator";
import {DataAPIClient} from "../../core";
import {IApplicationState} from "../index";

export const postAuditEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(DataActionEnum.Post_Audit),
    withLatestFrom(state$),
    switchMap(async ([action, state]: [IPostAuditAction, IApplicationState]) => {
      const data = {category: action.category, action: action.action};
      const response = await DataAPIClient.post(`AuditLogs`, data);
      if (response.status === 200) {
        return PostAuditSuccessAction();
      }
    }),
    catchError((x: any) => {
      const error = x.message;
      return of(PostAuditFailureAction(error));
    })
  );
