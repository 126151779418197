import {useFlags} from "launchdarkly-react-client-sdk";
import {Bar, BarChart, CartesianGrid, Cell, LabelList, XAxis, YAxis} from "recharts";
import {useTheme} from "styled-components";
import {IChartData} from "../../models";
import {roundNumber} from "../../helpers";

interface IProps {
  data: IChartData[];
  height: number;
  width: number;
  maximum: number;
  minimum: number;
}

const MINIMUM_Y_AXIS_LABEL_AMOUNT = 10;
const EXTRA_MARGIN_MULTIPLE_FOR_AXIS = 1.1;
const EXTRA_MARGIN_MULTIPLE_FOR_SMALL_VALUE_AXIS = 1.25;
const THRESHOLD_TO_BE_CONSIDERED_SMALL_VALUE = 30;

const PRECISION_FOR_TWO_DIGIT = 2;
const PRECISION_FOR_THREE_DIGIT = 3;

export function calculateMinMax(maximum: number, minimum: number) {
  let maxValue = maximum;
  let minValue = minimum;

  if (maxValue <= 0 || maxValue < MINIMUM_Y_AXIS_LABEL_AMOUNT) {
    maxValue = MINIMUM_Y_AXIS_LABEL_AMOUNT;
  }

  if (minValue > -MINIMUM_Y_AXIS_LABEL_AMOUNT || minValue >= 0) {
    minValue = -MINIMUM_Y_AXIS_LABEL_AMOUNT;
  }

  if (maxValue > 0 && minValue < 0) {
    if (Math.abs(maxValue) > Math.abs(minValue)) {
      minValue = -maxValue;
    } else {
      maxValue = -minValue;
    }
  }

  return [maxValue, minValue];
}

export const getPrecisionNumber = (value: number) =>
  Math.abs(value) >= 1000 ? PRECISION_FOR_THREE_DIGIT : PRECISION_FOR_TWO_DIGIT;

export const getYAxisTicks = (maxValue: number, minValue: number) => {
  const minMargin =
    minValue < -THRESHOLD_TO_BE_CONSIDERED_SMALL_VALUE
      ? EXTRA_MARGIN_MULTIPLE_FOR_AXIS
      : EXTRA_MARGIN_MULTIPLE_FOR_SMALL_VALUE_AXIS;
  const maxMargin =
    maxValue > THRESHOLD_TO_BE_CONSIDERED_SMALL_VALUE
      ? EXTRA_MARGIN_MULTIPLE_FOR_AXIS
      : EXTRA_MARGIN_MULTIPLE_FOR_SMALL_VALUE_AXIS;

  const minPrecision = getPrecisionNumber(minValue * minMargin);
  const maxPrecision = getPrecisionNumber(maxValue * maxMargin);

  const min = roundNumber(Number((minValue * minMargin).toPrecision(minPrecision)), 0);
  const halfMin = roundNumber(Number(((minValue * minMargin) / 2).toPrecision(minPrecision)), 0);
  const halfMax = roundNumber(Number(((maxValue * maxMargin) / 2).toPrecision(maxPrecision)), 0);
  const max = roundNumber(Number((maxValue * maxMargin).toPrecision(maxPrecision)), 0);

  return [min, halfMin, halfMax, max];
};

export const BarChartComponent = ({data, height, width, maximum, minimum}: IProps) => {
  const theme = useTheme();
  const {multipleAssets} = useFlags();
  const [maxValue, minValue] = calculateMinMax(maximum, minimum);
  const [min, halfMin, halfMax, max] = getYAxisTicks(maxValue, minValue);
  const yAxisTicks = [min, halfMin, 0, halfMax, max];

  return (
    <BarChart
      data-testid="chart"
      width={width}
      height={height}
      data={data}
      margin={{
        top: 15,
        right: 0,
        left: multipleAssets ? 127 : 80,
        bottom: 5,
      }}
    >
      <XAxis dataKey="period" style={{fontSize: 13, color: theme.background.raised}} />
      <YAxis type="number" domain={[min, max]} tickCount={5} ticks={yAxisTicks} />
      <CartesianGrid stroke={theme.text.onSurface.subtle} strokeDasharray="5 5" />
      <Bar type="monotone" dataKey="openPosition" data-testid="bar">
        <LabelList
          dataKey="openPosition"
          fill={theme.background.inverted}
          position="top"
          fontSize="85%"
          formatter={(value: number) => value.toFixed(1)}
          id="labels"
        />
        {data.map((entry) => (
          <Cell key={entry.key} fill={entry.openPosition > 0 ? theme.series[8] : theme.series[4]} />
        ))}
      </Bar>
    </BarChart>
  );
};
