import {Log, User, UserManager, UserManagerSettings} from "oidc-client-ts";
import {hasWindow} from "../../utils/app";

type AuthenticationEnvironment = {
  CLIENT_SCOPE: string;
  CLIENT_ID: string;
  CLIENT_ROOT: string;
  STS_AUTHORITY: string;
};

const getEnvironment = (): AuthenticationEnvironment => ({
  CLIENT_SCOPE: hasWindow()
    ? window?._env_?.REACT_APP_AUTH_CLIENT_SCOPE
    : process.env.REACT_APP_AUTH_CLIENT_SCOPE,
  CLIENT_ID: hasWindow()
    ? window?._env_?.REACT_APP_AUTH_CLIENT_ID
    : process.env.REACT_APP_AUTH_CLIENT_ID,
  CLIENT_ROOT: hasWindow()
    ? window?._env_?.REACT_APP_AUTH_CLIENT_ROOT
    : process.env.REACT_APP_AUTH_CLIENT_ROOT,
  STS_AUTHORITY: hasWindow()
    ? window?._env_?.REACT_APP_STS_AUTHORITY
    : process.env.REACT_APP_STS_AUTHORITY,
});

type RenewCallback =
  | ((
      success: boolean,
      token: string | undefined,
      email: string | undefined,
      username: string | undefined
    ) => void)
  | undefined;

export class AuthenticationService {
  public userManager: UserManager;
  private renewCallback: RenewCallback;
  private user: User | null;

  private constructor() {
    const settings: UserManagerSettings = {
      authority: getEnvironment().STS_AUTHORITY,
      client_id: getEnvironment().CLIENT_ID,
      redirect_uri: `${getEnvironment().CLIENT_ROOT}signin-callback`,
      silent_redirect_uri: `${getEnvironment().CLIENT_ROOT}silent-renew`,
      // tslint:disable-next-line:object-literal-sort-keysnp
      post_logout_redirect_uri: `${getEnvironment().CLIENT_ROOT}`,
      response_type: "code",
      scope: getEnvironment().CLIENT_SCOPE,
      response_mode: "query",
    };
    this.user = null;
    this.userManager = new UserManager(settings);

    this.userManager.events.addAccessTokenExpired(() => this.renewToken());
    this.userManager.events.addUserLoaded((user) => {
      this.user = user;
      if (this.renewCallback)
        this.renewCallback(
          user !== undefined,
          this.user?.access_token,
          this.user?.profile.email,
          this.user?.profile.preferred_username
        );
    });

    Log.setLogger(console);
    Log.setLevel(process.env.NODE_ENV === "development" ? Log.INFO : Log.WARN);
  }

  public static Instance = new AuthenticationService();

  public login(): Promise<void> {
    return this.userManager.signinRedirect({extraQueryParams: {kc_idp_hint: "oidc"}});
  }

  public async renewToken(): Promise<void> {
    await this.userManager.signinSilent().then((user) => {
      if (this.renewCallback)
        this.renewCallback(
          user !== undefined,
          user?.access_token,
          user?.profile.email,
          user?.profile.preferred_username
        );

      this.user = user;
    });
  }

  public getUserToken(): string | undefined {
    return this.user?.access_token;
  }
  public email(): string | undefined {
    return this.user?.profile.email;
  }
  public async getUser(): Promise<{
    token: string | undefined;
    email: string | undefined;
    username: string | undefined;
  }> {
    const user = await this.userManager.getUser();

    return {
      token: user?.access_token,
      email: user?.profile.email,
      username: user?.profile.preferred_username,
    };
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

  public async acceptCallback(): Promise<void> {
    await this.userManager.signinRedirectCallback();
  }

  public addRenewCallback(cb: RenewCallback) {
    if (this.renewCallback) {
      console.error("Renew callback already assigned");
      return;
    }
    this.renewCallback = cb;
  }
  public removeRenewCallback() {
    this.renewCallback = undefined;
  }
}
