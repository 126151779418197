import {useSelector} from "react-redux";
import {ShellThemeProvider} from "@sede-x/shell-ds-react-framework";
import {BrowserRouter} from "react-router-dom";

import {AuthenticationProvider} from "./pages/Authentication";
import {SignalRProvider} from "./pages/SignalR/SignalRContext";
import AppRouting from "./App.routing";
import {IApplicationState} from "./store";
import {getBasePath} from "./core";

require("./core/mocks");

function App() {
  const basepath = getBasePath();
  const themeMode = useSelector((state: IApplicationState) => state.data.themeMode);

  return (
    <ShellThemeProvider theme={themeMode}>
      <AuthenticationProvider>
        <BrowserRouter basename={basepath}>
          <SignalRProvider>
            <AppRouting />
          </SignalRProvider>
        </BrowserRouter>
      </AuthenticationProvider>
    </ShellThemeProvider>
  );
}

export default App;
