import {IAsset} from "../models/assets";

export const UKRenewables: IAsset = {
  name: "UK_Renewables",
  displayName: "UK_RENEWABLES",
  showForecast: true,
  forecastLabel: "Forecast",
  tradedPositionLabel: "Other-Hedges",
  intradayLabel: "ID-Continuous",
};
