import styled from "styled-components";

export const ScrollContainer = styled.div`
  overflow-x: scroll;
  overflow-y: auto;
  position: relative;
  outline: 0px solid transparent;
  height: calc(100% - 60px);
`;

export const TableOverlay = styled.div<{tableStart: number; height: number; right: number}>`
  position: absolute;
  left: ${(props) => props.tableStart}px;
  width: ${(props) => props.right - props.tableStart}px;
  top: 149px;
  height: ${(props) => props.height}px;
  background-color: ${({theme}) => theme.background.inverted};
  z-index: 2;
  opacity: 8%;
`;

export const ColouredHeaderUnderline = styled.div<{index: number}>`
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 2px;
  height: 2px;
  background-color: ${(props) => {
    if (props.index > 3) {
      return props.theme.series[10];
    }

    if (props.index > 1) {
      return props.theme.series[11];
    }

    return props.theme.series[12];
  }};
  z-index: 2;
`;

export const GraphOverlay = styled.div<{
  graphStart: number;
  height: number;
  right: number;
  top: number;
}>`
  position: absolute;
  left: ${(props) => props.graphStart}px;
  width: ${(props) => props.right - props.graphStart}px;
  top: ${(props) => props.top}px;
  height: ${(props) => props.height}px;
  background-color: ${({theme}) => theme.background.inverted};
  z-index: 2;
  opacity: 8%;
`;

export const NowLine = styled.div<{left: number; height: number; top: number}>`
  position: absolute;
  left: ${(props) => props.left}px;
  height: ${(props) => props.height}px;
  top: ${(props) => props.top}px;
  width: 1px;
  background-color: ${({theme}) => theme.accent.primary.strong};
  z-index: 2;
`;

export const GateClosureLine = styled.div<{left: number; height: number}>`
  position: absolute;
  left: ${(props) => props.left}px;
  height: ${(props) => props.height}px;
  top: 149px;
  width: 1px;
  background-color: ${({theme}) => theme.series[7]};
  z-index: 2;
`;

export const PNGateLine = styled.div<{left: number; height: number}>`
  position: absolute;
  left: ${(props) => props.left}px;
  height: ${(props) => props.height}px;
  top: 149px;
  width: 1px;
  background-color: ${({theme}) => theme.series[1]};
  z-index: 2;
`;

export const ExpanderLabel = styled.div<{paddingLeft: string}>`
  padding-left: ${(props) => props.paddingLeft};
`;
