import {combineReducers} from "redux";
import {combineEpics} from "redux-observable";
import {initialState as initialStateData} from "./data/state";
import {DataActions, IDataState} from "./data";
import dataReducer from "./data/reducer";
import {fetchDataEpic} from "./data/data-epic";
import {postAuditEpic} from "./data/audit-epic";
import {fetchMultipleAssetsDataEpic} from "./data/multiple-assets-data-epic";
import {fetchAssetDataEpic} from "./data/assets-data-epic";

export interface IApplicationState {
  readonly data: IDataState;
}

export const initialStateApplication: IApplicationState = {
  data: initialStateData,
};

export const rootReducer = combineReducers({
  data: dataReducer,
});

export const rootEpic = combineEpics<any, any, any | IApplicationState, any>(
  fetchDataEpic,
  fetchMultipleAssetsDataEpic,
  postAuditEpic,
  fetchAssetDataEpic
);

export {
  FetchDataAction,
  FetchDataSuccessAction,
  FetchDataFailureAction,
  FetchMultipleAssetsDataAction,
  FetchMultipleAssetsDataSuccessAction,
  FetchMultipleAssetsDataFailureAction,
  PostAuditAction,
  PostAuditSuccessAction,
  PostAuditFailureAction,
  FetchAssetDataAction,
  FetchAssetDataSuccessAction,
  FetchAssetDataFailureAction,
  AddAssetDataAction,
  RemoveAssetDataAction,
} from "./data/action-creator";

export type RootActions = DataActions;
