import {
  DataActionEnum,
  IAuthenticatedAction,
  IAuthenticatingAction,
  IFetchDataAction,
  IFetchDataFailureAction,
  IFetchDataSuccessAction,
  IFirstAuthenticatedAction,
  IFetchMultipleAssetsDataAction,
  IFetchMultipleAssetsDataFailureAction,
  IFetchMultipleAssetsDataSuccessAction,
  IPostAuditAction,
  IPostAuditFailureAction,
  IPostAuditSuccessAction,
  IResetToInitialStateAction,
  ISelectAssetAction,
  ISelectAssetsAction,
  ISelectTagAction,
  ISetDay1DateUpdatedToAction,
  IUpdateRefreshTimerAction,
  ISetThemeModeAction,
  IFetchAssetDataAction,
  IFetchAssetDataSuccessAction,
  IFetchAssetDataFailureAction,
  IAddAssetDataAction,
  IRemoveAssetDataAction,
} from ".";
import {IPositionInfo} from "../../models";
import {IAsset} from "../../models/assets";

export const FetchDataAction = (asset: string) => {
  return {
    type: DataActionEnum.Fetch_Data,
    asset,
  } as IFetchDataAction;
};

export const FetchDataSuccessAction = (data: IPositionInfo) => {
  return {
    type: DataActionEnum.Fetch_Data_Success,
    data,
  } as IFetchDataSuccessAction;
};

export const FetchDataFailureAction = (message: string) => {
  return {
    type: DataActionEnum.Fetch_Data_Failure,
    error: message,
  } as IFetchDataFailureAction;
};

export const FetchMultipleAssetsDataAction = (assets: string[], selectedAssets: IAsset[]) => {
  return {
    type: DataActionEnum.Fetch_Multiple_Assets_Data,
    assets,
    selectedAssets,
  } as IFetchMultipleAssetsDataAction;
};

export const FetchMultipleAssetsDataSuccessAction = (data: IPositionInfo) => {
  return {
    type: DataActionEnum.Fetch_Multiple_Assets_Data_Success,
    data,
  } as IFetchMultipleAssetsDataSuccessAction;
};

export const FetchMultipleAssetsDataFailureAction = (message: string) => {
  return {
    type: DataActionEnum.Fetch_Multiple_Assets_Data_Failure,
    error: message,
  } as IFetchMultipleAssetsDataFailureAction;
};

export const SelectAssetAction = (asset: string) => {
  return {
    type: DataActionEnum.Select_Asset,
    asset,
  } as ISelectAssetAction;
};

export const SelectAssetsAction = (assets: string[]) => {
  return {
    type: DataActionEnum.Select_Assets,
    assets,
  } as ISelectAssetsAction;
};

export const SelectTagAction = (tag: string) => {
  return {
    type: DataActionEnum.Select_Tag,
    tag,
  } as ISelectTagAction;
};

export const UpdateRefreshTimerAction = (seconds: number) => {
  return {
    type: DataActionEnum.Update_Refresh_Timer,
    seconds,
  } as IUpdateRefreshTimerAction;
};

export const SetLastUpdatedDay1DateAction = (day1Date: string) => {
  return {
    type: DataActionEnum.Set_Day1_Date_Updated_To,
    day1Date,
  } as ISetDay1DateUpdatedToAction;
};

export const ResetToInitialStateAction = (httpStatus: number) => {
  return {
    type: DataActionEnum.Reset_To_Initial_State,
    httpStatus,
  } as IResetToInitialStateAction;
};

export const AuthenticatedAction = (authenticated: boolean) => {
  return {
    type: DataActionEnum.Authenticated,
    authenticated,
  } as IAuthenticatedAction;
};

export const AuthenticatingAction = () => {
  return {
    type: DataActionEnum.Authenticating,
  } as IAuthenticatingAction;
};

export const FirstAuthenticatedAction = (firstAuthenticated: boolean) => {
  return {
    type: DataActionEnum.FirstAuthenticated,
    firstAuthenticated,
  } as IFirstAuthenticatedAction;
};

export const PostAuditAction = (category: string, action: string) => {
  return {
    type: DataActionEnum.Post_Audit,
    category,
    action,
  } as IPostAuditAction;
};

export const PostAuditSuccessAction = () => {
  return {
    type: DataActionEnum.Post_Audit_Success,
  } as IPostAuditSuccessAction;
};

export const PostAuditFailureAction = (message: string) => {
  return {
    type: DataActionEnum.Post_Audit_Failure,
    error: message,
  } as IPostAuditFailureAction;
};

export const SetThemeModeAction = (themeMode: string) => {
  return {
    type: DataActionEnum.Set_Theme_Mode,
    themeMode,
  } as ISetThemeModeAction;
};

export const FetchAssetDataAction = () => {
  return {
    type: DataActionEnum.Fetch_Asset_Data,
  } as IFetchAssetDataAction;
};

export const FetchAssetDataSuccessAction = (assetsMetadata: IAsset[]) => {
  return {
    type: DataActionEnum.Fetch_Asset_Data_Success,
    assetsMetadata,
  } as IFetchAssetDataSuccessAction;
};

export const FetchAssetDataFailureAction = (message: string) => {
  return {
    type: DataActionEnum.Fetch_Asset_Data_Failure,
    error: message,
  } as IFetchAssetDataFailureAction;
};

export const AddAssetDataAction = (asset: IAsset) => {
  return {
    type: DataActionEnum.Add_Asset_Data,
    asset,
  } as IAddAssetDataAction;
};

export const RemoveAssetDataAction = (asset: IAsset) => {
  return {
    type: DataActionEnum.Remove_Asset_Data,
    asset,
  } as IRemoveAssetDataAction;
};
