import {addHours, addMinutes, subMinutes, differenceInMinutes} from "date-fns";
import {fromZonedTime} from "date-fns-tz";

// if 11:44, take 15 mins ahead and round up to nearest half hour => 11:59 => 12:00, then subtract 15 mins => 11:45
// if 11:45, take 15 mins ahead and round up to nearest half hour => 12:00 => 12:30, then subtract 15 mins => 12:15
// if 11:47, take 15 mins ahead and round up to nearest half hour => 12:02 => 12:30, then subtract 15 mins => 12:15
export const getNextGateClosureTime = (time: Date) => {
  const currentTime = addMinutes(time, 15);
  const mins = currentTime.getMinutes();
  const diff = mins % 30;
  currentTime.setMinutes(mins + (30 - diff));
  currentTime.setSeconds(0);
  return subMinutes(currentTime, 15);
};

export const getPnGateTime = (time: Date) => {
  const mins = time.getMinutes();
  const diff = mins % 30;
  time.setMinutes(mins + (30 - diff));
  time.setSeconds(0);
  return addHours(subMinutes(time, 15), 1);
};

export const getGateLineIndentHalfHourly = (deliveryStartTime: string) => {
  const earliestDateTime = fromZonedTime(new Date(deliveryStartTime), "UTC");
  const nowTime = fromZonedTime(new Date(), "UTC");
  const gateClosureTime = getNextGateClosureTime(fromZonedTime(new Date(), "UTC"));
  const pnGateTime = getPnGateTime(fromZonedTime(new Date(), "UTC"));
  const nowTimeHalfHour = differenceInMinutes(nowTime, earliestDateTime) / 30;
  const gateClosureHalfHour = differenceInMinutes(gateClosureTime, earliestDateTime) / 30;
  const pnGateHalfHour = differenceInMinutes(pnGateTime, earliestDateTime) / 30;
  return {
    nowTimeHalfHour,
    gateClosureHalfHour,
    pnGateHalfHour,
  };
};

// --------------------------------Hourly--------------------------------//
export const getNextGateClosureHourlyTime = (time: Date) => {
  const currentTime = addMinutes(time, 30);
  const mins = currentTime.getMinutes();
  const diff = mins % 60;
  currentTime.setMinutes(mins + (60 - diff));
  currentTime.setSeconds(0);
  return subMinutes(currentTime, 30);
};

export const getPnGateHourlyTime = (time: Date) => {
  const currentTime = addMinutes(time, 30);
  const mins = currentTime.getMinutes();
  const diff = mins % 60;
  currentTime.setMinutes(mins + (60 - diff));
  currentTime.setSeconds(0);
  return addMinutes(currentTime, 30);
};

export const getGateLineIndentHourly = (deliveryStartTime: string) => {
  const earliestDateTime = fromZonedTime(new Date(deliveryStartTime), "UTC");
  const nowTime = fromZonedTime(new Date(), "UTC");
  const gateClosureTime = getNextGateClosureHourlyTime(fromZonedTime(new Date(), "UTC"));
  const pnGateTime = getPnGateHourlyTime(fromZonedTime(new Date(), "UTC"));
  const nowTimeHour = differenceInMinutes(nowTime, earliestDateTime) / 60;
  const gateClosureHour = differenceInMinutes(gateClosureTime, earliestDateTime) / 60;
  const pnGateHour = differenceInMinutes(pnGateTime, earliestDateTime) / 60;
  return {
    nowTimeHour,
    gateClosureHour,
    pnGateHour,
  };
};
