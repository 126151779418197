export const routes = {
  home: () => "/",
  position: () => "/PositionManagement",
  grid: () => "/grid",
  portfolio: () => "/portfolio",
  positionWithoutAssets: () => "/position",
  positionWithAssets: (assets: string[]) => `/position?Assets=${assets.join(",")}`,
  support: () => "/Support",
  help: () => "/Help",
  history: () => "/History",
  unauthorised: () => "/unauthorised",
  signInCallback: () => "/signin-callback",
  authenticating: () => "/authenticating",
};
