/* eslint-disable @typescript-eslint/default-param-last */
import {Reducer} from "redux";
import {DataActionEnum, DataActions} from ".";
import {initialState, IDataState} from "./state";
import {gbAssets} from "../../constants";

const HTTP_STATUS_UNAUTHORISED = 401;
const HTTP_STATUS_FORBIDDEN = 403;

const reducer: Reducer<IDataState, DataActions> = (state = initialState, action: DataActions) => {
  switch (action.type) {
    case DataActionEnum.Fetch_Data:
      return {
        ...state,
        asset: action.asset,
        loading: true,
        errorMessage: "",
      };
    case DataActionEnum.Fetch_Data_Success:
    case DataActionEnum.Fetch_Multiple_Assets_Data_Success:
      return {
        ...state,
        data: action.data,
        hasloadedBefore: true,
        loading: false,
      };
    case DataActionEnum.Fetch_Asset_Data_Failure:
    case DataActionEnum.Fetch_Data_Failure:
    case DataActionEnum.Fetch_Multiple_Assets_Data_Failure:
      return {
        ...state,
        loading: false,
        errorMessage: action?.errorMessage ? action.errorMessage : "",
      };
    case DataActionEnum.Fetch_Multiple_Assets_Data:
      return {
        ...state,
        assets: action.assets,
        loading: true,
        errorMessage: "",
      };
    case DataActionEnum.Select_Asset:
      return {
        ...state,
        asset: action?.asset,
        hasloadedBefore: false,
      };
    case DataActionEnum.Select_Assets:
      return {
        ...state,
        assets: action?.assets,
        tag: undefined,
        hasloadedBefore: false,
      };
    case DataActionEnum.Select_Tag:
      return {
        ...state,
        assets: [],
        tag: action?.tag,
        hasloadedBefore: false,
      };
    case DataActionEnum.Update_Refresh_Timer:
      return {
        ...state,
        refreshTime: action?.seconds,
      };
    case DataActionEnum.Set_Day1_Date_Updated_To:
      return {
        ...state,
        lastDay1DateUpdatedTo: action?.day1Date,
      };
    case DataActionEnum.Reset_To_Initial_State:
      return {
        ...initialState,
        unauthenticated: action?.httpStatus === HTTP_STATUS_UNAUTHORISED,
        unauthorised: action?.httpStatus === HTTP_STATUS_FORBIDDEN,
      };
    case DataActionEnum.Authenticated:
      return {
        ...state,
        unauthenticated: false,
        unauthorised: false,
        authenticating: false,
        authenticated: true,
        firstAuthenticated: true,
      };
    case DataActionEnum.Authenticating:
      return {
        ...state,
        authenticating: true,
        authenticated: false,
      };
    case DataActionEnum.FirstAuthenticated:
      return {
        ...state,
        firstAuthenticated: false,
      };
    case DataActionEnum.Post_Audit:
      return {
        ...state,
        errorMessage: "",
      };
    case DataActionEnum.Post_Audit_Success:
      return {
        ...state,
      };
    case DataActionEnum.Post_Audit_Failure:
      return {
        ...state,
        errorMessage: action?.errorMessage ? action.errorMessage : "",
      };
    case DataActionEnum.Set_Theme_Mode:
      return {
        ...state,
        themeMode: action?.themeMode,
      };
    case DataActionEnum.Fetch_Asset_Data:
      return {
        ...state,
      };
    case DataActionEnum.Fetch_Asset_Data_Success:
      return {
        ...state,
        assetsMetaData: action.assetsMetadata.filter((asset) => gbAssets.includes(asset.name)),
        selectedAssets: [],
        assets: action.assetsMetadata.map((x) => x.name),
      };
    case DataActionEnum.Add_Asset_Data:
      return {
        ...state,
        selectedAssets: state.assetsMetaData.find(
          (asset) => asset.displayName.toUpperCase() === action.asset.displayName.toUpperCase()
        )
          ? state.assetsMetaData
          : state.assetsMetaData.concat(action.asset),
      };
    case DataActionEnum.Remove_Asset_Data:
      return {
        ...state,
        selectedAssets: state.assetsMetaData.filter(
          (asset) => asset.displayName.toUpperCase() !== action.asset.displayName.toUpperCase()
        ),
      };
    default:
      return state;
  }
};

export default reducer;
