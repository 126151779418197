import {
  Button,
  IModalProps,
  Modal,
  Sizes,
  Variants,
  Text,
  Checkbox,
} from "@sede-x/shell-ds-react-framework";
import {ReactElement, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {useNavigate} from "react-router";
import {SelectAssetsAction} from "../../store/data/action-creator";
import {routes} from "../../routes";
import {compareString} from "../../utils";
import {IApplicationState} from "../../store";

const StyledCheckbox = styled(Checkbox)`
  margin: 10px;
`;

const FlowDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
`;

interface IProps extends IModalProps {
  initialValues: string[];
}

export const SelectAssets = ({onClose, open, initialValues}: IProps): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [assets, setAssets] = useState(initialValues);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const assetsList = useSelector((state: IApplicationState) => state.data.selectedAssets).map(
    (x) => x.name
  );
  const numberOfAssets = assetsList.length;

  const checkAllSetting = (assets1: string[]) => {
    setAllSelected(assets1.length === numberOfAssets);
  };

  useEffect(() => {
    setAssets(initialValues);
    checkAllSetting(initialValues);
  }, [open]);

  const selectAll = () => {
    setAssets(allSelected ? [] : assetsList);
    setAllSelected(!allSelected);
  };

  const onChange = (e: string) => {
    let assetsToChange = assets.slice(0);
    if (!assetsToChange.some((item: string) => item === e)) {
      assetsToChange.push(e);
    } else {
      assetsToChange = assetsToChange.filter((item: string) => item !== e);
    }
    setAssets(assetsToChange);
    checkAllSetting(assetsToChange);
  };

  const onSave = () => {
    if (assets !== initialValues) {
      const selectAssets =
        [...assets].sort(compareString).join(",") === [...assetsList].sort(compareString).join(",")
          ? ["All"]
          : assets;
      dispatch(SelectAssetsAction(selectAssets));
      navigate(routes.positionWithAssets(selectAssets));
    }
    onClose();
  };

  return (
    <Modal
      open={open}
      title="Select Assets"
      showFooter
      description="Choose the assets to display data for"
      onClose={() => {
        onClose();
        if (onClose) onClose();
      }}
      actions={[]}
      footer={
        <Text size={Sizes.Medium}>
          <FlowDiv>
            <div>
              <StyledCheckbox
                id="checkBoxall"
                data-testid="checkBoxall"
                label="All"
                checked={allSelected}
                onChange={() => selectAll()}
              />
            </div>
            <div>
              <Button
                id="btnCancel"
                variant={Variants.Outlined}
                onClick={() => onClose()}
                style={{marginRight: 8}}
              >
                Cancel
              </Button>
              <Button
                id="btnSave"
                disabled={assets.length === 0}
                variant={Variants.Filled}
                onClick={() => onSave()}
              >
                Save
              </Button>
            </div>
          </FlowDiv>
        </Text>
      }
    >
      <Text size={Sizes.Medium}>
        {assetsList.map((x, i) => (
          <div>
            <StyledCheckbox
              key={x}
              data-testid={`checkBox${i}`}
              id={`checkBox${i}`}
              label={x}
              checked={assets.some((y: string) => y === x)}
              name={x}
              onChange={() => onChange(x)}
              value={x}
            />
          </div>
        ))}
      </Text>
    </Modal>
  );
};
