import ReactDOM from "react-dom/client";
import {Provider as ReduxProvider} from "react-redux";
import {ApplicationInsights} from "@microsoft/applicationinsights-web";
import {ReactPlugin} from "@microsoft/applicationinsights-react-js";
import {asyncWithLDProvider} from "launchdarkly-react-client-sdk";
import {hasWindow} from "./utils/app";
import {store} from "./Configure.Store";
import App from "./App";
import "./index.css";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "0946e5b5-84a6-43cc-a153-1a60b0e2c774",
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});

appInsights.loadAppInsights();

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: hasWindow()
      ? window?._env_?.REACT_APP_LD_CLIENT_ID
      : process.env.REACT_APP_LD_CLIENT_ID,
    options: {},
  });

  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  root.render(
    <ReduxProvider store={store}>
      <LDProvider>
        <App />
      </LDProvider>
    </ReduxProvider>
  );
})();
