import {Loading} from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

export const LoadingIndicator = () => (
  <LoadingContainer>
    <Loading type="default" />
  </LoadingContainer>
);

const LoadingContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 35px;
`;
