import {useEffect, useState, FC} from "react";
import styled from "styled-components";
import {useSignalR} from "../../pages/SignalR/SignalRContext";
import {getBuildNumber, getEnvironmentName} from "../../core";
import {IDeploymentNotificationMessage} from "../../models/message";
import {ConfirmDialog} from "../ConfirmDialog/ConfirmDialog";

const EnvHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 60px;
  font-size: 16px;
  line-height: 20px;
  margin-top: 5px;
`;

const EnvName = styled.div`
  font-size: 16px;
  text-align: center;
`;

const BuildName = styled.div`
  font-size: 12px;
  text-align: center;
`;

const appName = "cratos-ui";

export const refreshScreen = () => {
  const {serviceWorker} = navigator;
  if (serviceWorker) {
    serviceWorker.getRegistrations().then((registrations) => {
      if (registrations) {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      }
    });
  }

  if (window.caches) {
    window.caches.keys().then((keyList) => {
      return Promise.all(
        keyList.map((key) => {
          return caches.delete(key);
        })
      );
    });
  }

  // To hard refresh the page
  setTimeout(() => {
    const timestamp = new Date().getTime();
    const newUrl = `${window.location.href}#${timestamp}`;
    window.history.replaceState(null, "", newUrl);
    window.location.reload();
  }, 5);
};

export const EnvironmentHeader: FC = () => {
  const {onMessageReceived} = useSignalR();
  const env = getEnvironmentName();

  const build = getBuildNumber();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    onMessageReceived("DeploymentNotification", (message: IDeploymentNotificationMessage) => {
      if (
        message.application.toUpperCase() !== appName.toUpperCase() ||
        message.environment.toUpperCase() !== env.toUpperCase()
      ) {
        return;
      }

      if (message.forceReload) {
        refreshScreen();
      } else if (message.buildNumber !== build) {
        setShowConfirmDialog(true);
      }
    });
  }, [env, build, onMessageReceived]);

  return (
    <>
      <EnvHeader>
        <EnvName>{env}</EnvName>
        <BuildName>{build}</BuildName>
      </EnvHeader>
      {showConfirmDialog && (
        <ConfirmDialog
          open={showConfirmDialog}
          title="Reload Cratos"
          description="A new version is available"
          content="A new update for Cratos is available. Would you like to refresh now to apply the update?"
          onConfirm={() => refreshScreen()}
          onCancel={() => setShowConfirmDialog(false)}
          confirmButtonText="Refresh"
          cancelButtonText="Cancel"
          onClose={() => setShowConfirmDialog(false)}
        />
      )}
    </>
  );
};
