import {Suspense, lazy, useEffect, useState} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router";
import styled from "styled-components";
import {ToastContainer} from "react-toastify";
import {useSelector, useDispatch} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {useFlags, useLDClient} from "launchdarkly-react-client-sdk";
import {
  Unauthorised,
  useAuthentication,
  SignInCallback,
  Authenticating,
} from "./pages/Authentication";
import {IApplicationState} from "./store";
import {
  FetchAssetDataAction,
  AddAssetDataAction,
  RemoveAssetDataAction,
  FirstAuthenticatedAction,
  PostAuditAction,
} from "./store/data/action-creator";
import useRouteStore from "./store/routes/routeStore";
import {routes} from "./routes";
import {NavMenu} from "./components";

import Portfolio from "./pages/Portfolio";
import PositionManagementPage from "./pages/PositionManagement";
import PositionManagementMultiplePage from "./pages/PositionManagementMultiple";

import "react-toastify/dist/ReactToastify.css";
import {AuthenticationService} from "./pages/Authentication/AuthenticationService";
import {UKRenewables} from "./constants";
import Maintenace from "./pages/Maintenance/Maintenance";

const HelpPage = lazy(() => import("./pages/Help/Help"));
const SupportPage = lazy(() => import("./pages/Support"));
const PortfolioHourly = lazy(() => import("./pages/Portfolio_hourly"));

const PageStyle = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({theme}) => theme.background.surface};
`;

type ApiErrorState = {
  unauthorisedFromApi: boolean;
  unauthenticatedFromApi: boolean;
};

const getRoutePath = (pathname: string, searchParams: URLSearchParams): string => {
  const params = searchParams.get("Assets");
  return params ? `${pathname}?Assets=${params}` : pathname;
};

const AppRouting: React.FC = () => {
  const {isAuthenticated, login} = useAuthentication();
  const {unauthorisedFromApi, unauthenticatedFromApi} = useSelector<
    IApplicationState,
    ApiErrorState
  >((state) => ({
    unauthorisedFromApi: state.data.unauthorised,
    unauthenticatedFromApi: state.data.unauthenticated,
  }));
  const ldClient = useLDClient();
  const {multipleAssets, rolloutPpaUkRenewables, configureUiMaintenanceMode} = useFlags();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const [searchParams] = useSearchParams();
  const firstAuthenticated = useSelector(
    (state: IApplicationState) => state.data.firstAuthenticated
  );
  const url = getRoutePath(pathname, searchParams);
  const [isFirstRun, setIsFirstRun] = useState<boolean>(true);
  const assetsMetaData = useSelector((state: IApplicationState) => state.data.assetsMetaData);
  const initialUrl = useRouteStore((state) => state.routes);
  const addRoute = useRouteStore((state) => state.addRoutes);
  const clearRoutes = useRouteStore((state) => state.clearRoutes);
  const positionRoute = multipleAssets ? routes.positionWithAssets(["All"]) : routes.home();
  const initialRoute =
    initialUrl.length === 0 || initialUrl[0] === "/" ? positionRoute : initialUrl[0];

  useEffect(() => {
    if (assetsMetaData.length > 0) {
      if (rolloutPpaUkRenewables) {
        dispatch(AddAssetDataAction(UKRenewables));
      } else {
        dispatch(RemoveAssetDataAction(UKRenewables));
      }
    }
  }, [rolloutPpaUkRenewables, assetsMetaData]);

  useEffect(() => {
    navigate(multipleAssets ? routes.positionWithAssets(["All"]) : routes.position());
  }, [multipleAssets]);

  useEffect(() => {
    if (unauthorisedFromApi || unauthenticatedFromApi) {
      return;
    }

    // external login required
    if (
      !isAuthenticated &&
      pathname !== routes.unauthorised() &&
      pathname !== routes.signInCallback() &&
      !isFirstRun
    ) {
      login();
    }

    // see if can autorenew token and not require external login
    if (
      !isAuthenticated &&
      pathname !== routes.unauthorised() &&
      pathname !== routes.signInCallback()
    ) {
      if (isFirstRun) {
        clearRoutes();
        addRoute(url);
      }
      navigate(routes.authenticating());
      setIsFirstRun(false);
    }

    const identifyUser = async () => {
      const user = await AuthenticationService.Instance.getUser();
      ldClient?.identify({
        key: user.username,
        email: user.email,
      });
    };

    // successful login
    if (isAuthenticated && firstAuthenticated) {
      identifyUser();
      dispatch(PostAuditAction("Security", "Login Success"));
      dispatch(FirstAuthenticatedAction(false));
      dispatch(FetchAssetDataAction());
    }

    // successful login from a failed page needs redirection
    if (
      isAuthenticated &&
      (pathname === routes.unauthorised() || pathname === routes.authenticating())
    ) {
      dispatch(FetchAssetDataAction());
      navigate(initialRoute);
      clearRoutes();
    }
  }, [isAuthenticated, pathname, unauthenticatedFromApi, unauthorisedFromApi]);

  useEffect(() => {
    if ((unauthorisedFromApi || unauthenticatedFromApi) && pathname !== routes.unauthorised()) {
      dispatch(PostAuditAction("Security", "Login Failure"));
      navigate(routes.unauthorised());
      clearRoutes();
    }
  }, [unauthorisedFromApi, unauthenticatedFromApi]);

  return (
    <PageStyle>
      {pathname !== routes.unauthorised() && !configureUiMaintenanceMode && <NavMenu />}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {!multipleAssets && (
            <Route
              path="/"
              element={
                <Maintenace>
                  <PositionManagementPage />
                </Maintenace>
              }
            />
          )}
          {!multipleAssets && (
            <Route
              path={routes.position()}
              element={
                <Maintenace>
                  <PositionManagementPage />
                </Maintenace>
              }
            />
          )}
          {multipleAssets && (
            <Route
              path="/"
              element={
                <Maintenace>
                  <PositionManagementMultiplePage />
                </Maintenace>
              }
            />
          )}
          {multipleAssets && (
            <Route
              path={routes.positionWithoutAssets()}
              element={
                <Maintenace>
                  <PositionManagementMultiplePage />
                </Maintenace>
              }
            />
          )}
          {multipleAssets && (
            <Route
              path={routes.grid()}
              element={
                <Maintenace>
                  <Portfolio />
                </Maintenace>
              }
            />
          )}
          {multipleAssets && (
            <Route
              path={routes.portfolio()}
              element={
                <Maintenace>
                  <PortfolioHourly />
                </Maintenace>
              }
            />
          )}
          <Route
            path={routes.support()}
            element={
              <Maintenace>
                <SupportPage />
              </Maintenace>
            }
          />
          <Route
            path={routes.help()}
            element={
              <Maintenace>
                <HelpPage />
              </Maintenace>
            }
          />
          <Route path={routes.unauthorised()} element={<Unauthorised />} />
          <Route
            path={routes.signInCallback()}
            element={<SignInCallback returnUrl={initialRoute} />}
          />
          <Route path={routes.authenticating()} element={<Authenticating />} />
        </Routes>
      </Suspense>
      <ToastContainer autoClose={3000} closeOnClick draggable />
    </PageStyle>
  );
};

export default AppRouting;
