import {Sizes, TBody, TD, TH, THead, TR, Table} from "@sede-x/shell-ds-react-framework";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {addDays, isBefore, subDays} from "date-fns";
import {fromZonedTime} from "date-fns-tz";
import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "styled-components";
import {BarChartComponent, LoadingIndicator, PageWrapper} from "../../components";
import {refreshScreen} from "../../components/NavMenu/EnvironmentHeader";
import {getRefreshTimeInSeconds} from "../../core";
import {
  getDay1DateForCurrentTime,
  renderCentred,
  renderLeftAligned,
  renderVolume,
  roundNumber,
} from "../../helpers";
import {useWindowSize} from "../../hooks";
import {Day, IChartData, IPosition, ITableData, ITableDataDetail} from "../../models";
import {FetchDataAction, IApplicationState} from "../../store";
import {UpdateRefreshTimerAction} from "../../store/data/action-creator";
import {
  ColouredHeaderUnderline,
  GateClosureLine,
  GraphOverlay,
  NowLine,
  PNGateLine,
  ScrollContainer,
  TableOverlay,
} from "../../styles/Position.Styled";
import {getGateLineIndentHalfHourly} from "../../utils";

const OPEN_POSITION = "Open Position";

const NUMBER_COLUMNS_TILL_BLOCK_1 = 0;
const NUMBER_COLUMNS_TILL_BLOCK_2 = 8;
const NUMBER_COLUMNS_TILL_BLOCK_3 = 16;
const NUMBER_COLUMNS_TILL_BLOCK_4 = 24;
const NUMBER_COLUMNS_TILL_BLOCK_5 = 32;
const NUMBER_COLUMNS_TILL_BLOCK_6 = 40;
const NUMBER_COLUMNS_TILL_BLOCK_7 = 48;
const NUMBER_COLUMNS_TILL_BLOCK_8 = 56;
const NUMBER_COLUMNS_TILL_BLOCK_9 = 64;
const NUMBER_COLUMNS_TILL_BLOCK_10 = 72;
const NUMBER_COLUMNS_TILL_BLOCK_11 = 80;
const NUMBER_COLUMNS_TILL_BLOCK_12 = 88;
const NUMBER_COLUMNS_TILL_BLOCK_13 = 96;
const NUMBER_COLUMNS_TILL_BLOCK_14 = 104;
const NUMBER_COLUMNS_TILL_BLOCK_15 = 112;

const TABLE_FIRST_COLUMN_WIDTH = 135;
const TABLE_COLUMN_WIDTH = 90;
const GRAPH_START_POSITION_PIXELS = 135;
const GRAPH_OVERLAY_START_POSITION_PIXELS = 139;
const FIXED_TABLE_HEIGHT = 232;
const NEWLINE_HEIGHT_DIFF = 50;
const GATE_LINE_HEIGHT_DIFF = 68;
const SCREEN_HEIGHT = 1200;
const MIN_TABLE_HEIGHT = 430;
const SCROLL_DOWN_DELAY = 1200;
const GRAPH_NOW_LINE_TOP = 363;
const TABLE_OVERLAY_HEIGHT = 198;

const PositionManagement: React.FC = () => {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const theme = useTheme();
  const ref = useRef<HTMLElement>(null);

  const data = useSelector((state: IApplicationState) => state.data.data);
  const positions = useSelector((state: IApplicationState) =>
    state.data.data ? state.data.data.positions : ([] as IPosition[])
  );
  const isLoading = useSelector((state: IApplicationState) => state.data.loading);
  const hasloadedBefore = useSelector((state: IApplicationState) => state.data.hasloadedBefore);
  const asset = useSelector((state: IApplicationState) => state.data.asset);
  const refreshTime = useSelector((state: IApplicationState) => state.data.refreshTime);
  const lastDay1DateUpdatedTo = useSelector(
    (state: IApplicationState) => state.data.lastDay1DateUpdatedTo
  );

  const fixedChartHeight = (size.height ? size.height : SCREEN_HEIGHT) - MIN_TABLE_HEIGHT;
  const chartHeight = fixedChartHeight > FIXED_TABLE_HEIGHT ? fixedChartHeight : FIXED_TABLE_HEIGHT;
  const newLineHeight = chartHeight - NEWLINE_HEIGHT_DIFF;
  const gateLineHeight = FIXED_TABLE_HEIGHT + chartHeight - GATE_LINE_HEIGHT_DIFF;

  const [tableWidth, setTableWidth] = useState<number>(0);
  const [nowLineIndent, setNowLineIndent] = useState<number>(GRAPH_START_POSITION_PIXELS);
  const [gateClosureLineIndent, setGateClosureLineIndent] = useState<number>(
    GRAPH_START_POSITION_PIXELS
  );
  const [pnGateLineIndent, setPnGateLineIndent] = useState<number>(GRAPH_START_POSITION_PIXELS);

  const getBackGroundColor = (row: any, index: number): string | null => {
    const isAssetOrPosition = row.type === OPEN_POSITION;

    if (isAssetOrPosition && row[index - 1].value > 0) {
      return theme.series[8];
    }

    if (isAssetOrPosition && row[index - 1].value < 0) {
      return theme.series[4];
    }
    return null;
  };

  useEffect(() => {
    dispatch(FetchDataAction(asset));
    dispatch(UpdateRefreshTimerAction(getRefreshTimeInSeconds()));
  }, [dispatch, asset]);

  const scrollToNow = () => {
    const scrollContainer: HTMLElement | null = document.getElementById(
      "horizontal-scroll-container"
    );
    scrollContainer?.scrollTo({
      left:
        Math.round(nowLineIndent / TABLE_COLUMN_WIDTH) * TABLE_COLUMN_WIDTH -
        TABLE_COLUMN_WIDTH * 7,
      behavior: "smooth",
    });
    scrollContainer?.focus();
  };

  const scrollToBottom = () => {
    const scrollContainer: HTMLElement | null = document.getElementById(
      "horizontal-scroll-container"
    );
    scrollContainer?.scrollTo({
      top: scrollContainer.scrollHeight,
      behavior: "smooth",
    });
    scrollContainer?.focus();
  };

  const updateMarkerLines = () => {
    if (!positions || positions.length === 0) {
      setNowLineIndent(0);
      setGateClosureLineIndent(0);
      setPnGateLineIndent(0);
    } else {
      const {nowTimeHalfHour, gateClosureHalfHour, pnGateHalfHour} = getGateLineIndentHalfHourly(
        positions[0].deliveryStartTime
      );

      setNowLineIndent(
        Math.round(GRAPH_START_POSITION_PIXELS + nowTimeHalfHour * TABLE_COLUMN_WIDTH)
      );
      setGateClosureLineIndent(
        Math.round(GRAPH_START_POSITION_PIXELS + gateClosureHalfHour * TABLE_COLUMN_WIDTH) +
          TABLE_COLUMN_WIDTH / 2
      );
      setPnGateLineIndent(
        Math.round(GRAPH_START_POSITION_PIXELS + pnGateHalfHour * TABLE_COLUMN_WIDTH) +
          TABLE_COLUMN_WIDTH / 2
      );
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(UpdateRefreshTimerAction(refreshTime - 1));

      const day1Date = getDay1DateForCurrentTime();
      const day1D = fromZonedTime(new Date(lastDay1DateUpdatedTo), "Europe/London");
      // end of EFA day refresh
      if (isBefore(day1D, day1Date)) {
        refreshScreen();
      }
      // regular refresh
      if (refreshTime === 0) {
        dispatch(FetchDataAction(asset));
        dispatch(UpdateRefreshTimerAction(getRefreshTimeInSeconds()));
      }
      // update lines every 5 seconds
      if (refreshTime % 5 === 0) {
        updateMarkerLines();
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    updateMarkerLines();
  }, [data, positions]);

  useLayoutEffect(() => {
    const scrollLeftTimer = setTimeout(() => {
      if (ref.current) {
        setTableWidth(ref.current ? ref.current.offsetWidth : 0);
        scrollToNow();
      }
    }, 2);
    const timer = setTimeout(() => {
      scrollToBottom();
    }, SCROLL_DOWN_DELAY);
    return () => {
      clearTimeout(scrollLeftTimer);
      clearTimeout(timer);
    };
  }, [ref.current, tableWidth]);

  const transformDataForTable = (originalData: IPosition[]) => {
    let uniqueTypes = [] as string[];
    const pivotedData = [] as ITableData[];

    pivotedData.push(
      originalData.reduce((pivotedObj: any, obj) => {
        if (obj["type"] === "Forecast") {
          pivotedObj["type"] = "Delivery Start";
          pivotedObj[obj.periodOrder] = {
            key: `DeliveryStart${obj["periodOrder"]}`,
            value: obj["startTimeUk"],
            dataType: "time",
          };
        }
        return pivotedObj;
      }, {})
    );

    // Totals
    pivotedData.push(
      originalData.reduce((pivotedObj: any, obj) => {
        pivotedObj["type"] = OPEN_POSITION;
        pivotedObj[obj.periodOrder] = pivotedObj[obj.periodOrder]
          ? {
              key: `OpenPosition${obj["periodOrder"]}`,
              value: pivotedObj[obj.periodOrder].value + (obj["value"] ? obj["value"] : 0),
              dataType: "volume",
            }
          : {
              key: `OpenPosition${obj["periodOrder"]}`,
              value: obj["value"] ? obj["value"] : 0,
              dataType: "volume",
              context: OPEN_POSITION,
            };
        return pivotedObj;
      }, {})
    );

    uniqueTypes = originalData.reduce((uniqueValues: string[], obj) => {
      if (!uniqueValues.includes(obj["type"])) {
        uniqueValues.push(obj["type"]);
      }
      return uniqueValues;
    }, []);
    uniqueTypes.forEach((item) => {
      pivotedData.push(
        originalData.reduce((pivotedObj: any, obj) => {
          if (item === obj["type"]) {
            pivotedObj["type"] = obj["type"];
            // title context correction - make plural
            if (obj["assetName"] === "IC_UK" && pivotedObj["type"] === "Forecast") {
              pivotedObj["type"] = "Nominations";
            }

            if (pivotedObj["type"] === "TradedPosition") {
              pivotedObj["type"] = "Other-Hedges";
            }
            if (pivotedObj["type"] === "IntradayAggregation") {
              pivotedObj["type"] = "ID-Continuous";
            }
            if (!pivotedObj[obj.periodOrder]) {
              pivotedObj[obj.periodOrder] = {
                key: pivotedObj["type"] + obj["periodOrder"],
              };
            }
            pivotedObj[obj.periodOrder] = pivotedObj[obj.periodOrder]
              ? {
                  key: pivotedObj["type"] + obj["periodOrder"],
                  value: obj["value"],
                  dataType: "volume",
                  period: obj["period"],
                }
              : 0;
          }
          return pivotedObj;
        }, {})
      );
    });
    return pivotedData;
  };

  const transformDataForChart = (originalData: IPosition[]) => {
    const data: IChartData[] = originalData.reduce((pivotedObj: IChartData[], obj) => {
      const value = obj["value"] ? obj["value"] : 0;
      pivotedObj[obj.periodOrder] = {
        openPosition: (obj["value"] =
          pivotedObj[obj.periodOrder] && pivotedObj[obj.periodOrder].openPosition
            ? roundNumber(pivotedObj[obj.periodOrder].openPosition + value, 1)
            : value),
        period: obj["period"],
        key: `${obj["deliveryStartTime"]}-${obj["periodOrder"]}`,
      } as IChartData;
      return pivotedObj;
    }, [] as IChartData[]);
    return data;
  };

  const getColumnNames = (originalData: IPosition[]) => {
    return originalData.reduce((pivotedObj: any, obj) => {
      if (obj["type"] === "Forecast") {
        pivotedObj[obj.periodOrder] = obj["period"] ? obj["period"] : "";
      }
      return pivotedObj;
    }, {});
  };

  const transformedTableData: ITableData[] = useMemo(
    () => positions && transformDataForTable(positions),
    [data, positions]
  );

  const transformedChartData: IChartData[] = useMemo(
    () => positions && transformDataForChart(positions),
    [data, positions]
  );

  function getMinimumOpenPosition() {
    const min = transformedChartData.reduce((acc, val) => {
      return acc.openPosition < val.openPosition ? acc : val;
    });
    return min.openPosition;
  }

  function getMaximumOpenPosition() {
    const max = transformedChartData.reduce((acc, val) => {
      return acc.openPosition > val.openPosition ? acc : val;
    });
    return max.openPosition;
  }

  const columnNames: string[] = useMemo(() => positions && getColumnNames(positions), [positions]);

  const getCellData = (info: any, index: number) =>
    (info.row.original as ITableData)[index] as ITableDataDetail;

  const columnHelper = createColumnHelper<ITableData>();

  const NUMBER_PERIODS_IN_HALF_BLOCK = 4;
  const NUMBER_PERIODS_IN_HALF_BLOCK_ON_SHORT_DAY = 2;
  const NUMBER_PERIODS_IN_HALF_BLOCK_ON_LONG_DAY = 6;
  const NUMBER_COLUMNS_TILL_SHORT_DAY_DAY_1_CLOCK_CHANGE = 29;
  const NUMBER_COLUMNS_TILL_LONG_DAY_DAY_1_CLOCK_CHANGE = 31;
  const NUMBER_COLUMNS_TILL_SHORT_DAY_DAY_2_CLOCK_CHANGE = 77;
  const NUMBER_COLUMNS_TILL_LONG_DAY_DAY_2_CLOCK_CHANGE = 79;
  const SHORT_DAY_COLUMN_OFFSET = -2;
  const LONG_DAY_COLUMN_OFFSET = 2;
  const OFFSET_AFTER_DAY_1_BLOCK_1 =
    data.day1Type === Day.ShortDay
      ? SHORT_DAY_COLUMN_OFFSET
      : data.day1Type === Day.LongDay
        ? LONG_DAY_COLUMN_OFFSET
        : 0;
  const OFFSET_AFTER_DAY_2_BLOCK_1 =
    data.day1Type === Day.ShortDay || data.day2Type === Day.ShortDay
      ? SHORT_DAY_COLUMN_OFFSET
      : data.day1Type === Day.LongDay || data.day2Type === Day.LongDay
        ? LONG_DAY_COLUMN_OFFSET
        : 0;

  const getBlock = (
    dayNumber: number,
    blockNumber: number,
    periodStartingPoint: number,
    isShortDay?: boolean,
    isLongDay?: boolean
  ) =>
    columnHelper.group({
      id: `day${dayNumber.toString()}block${blockNumber.toString()}`,
      header: () => renderCentred(blockNumber.toString()),
      footer: (props) => props.column.id,
      columns: [
        columnHelper.group({
          id: `day${dayNumber.toString()}block${blockNumber.toString()}hblock1`,
          header: () => renderCentred(`${blockNumber.toString()}A`),
          footer: (props) => props.column.id,
          columns: Array.from(Array(NUMBER_PERIODS_IN_HALF_BLOCK)).map((x, index) =>
            columnHelper.accessor((index + periodStartingPoint).toString(), {
              id: (index + periodStartingPoint).toString(),
              cell: (info) =>
                getCellData(info, index + periodStartingPoint)?.dataType === "time"
                  ? renderCentred(getCellData(info, index + periodStartingPoint)?.value)
                  : renderVolume(getCellData(info, index + periodStartingPoint)?.value?.toString()),
              header: () =>
                renderCentred(
                  columnNames[index + periodStartingPoint]
                    ? columnNames[index + periodStartingPoint]
                    : ""
                ),
              size: TABLE_COLUMN_WIDTH,
              minSize: TABLE_COLUMN_WIDTH,
            })
          ),
        }),
        columnHelper.group({
          id: `day${dayNumber.toString()}block${blockNumber.toString()}hblock2`,
          header: () => renderCentred(`${blockNumber.toString()}B`),
          footer: (props) => props.column.id,
          columns: Array.from(
            Array(
              isShortDay
                ? NUMBER_PERIODS_IN_HALF_BLOCK_ON_SHORT_DAY
                : isLongDay
                  ? NUMBER_PERIODS_IN_HALF_BLOCK_ON_LONG_DAY
                  : NUMBER_PERIODS_IN_HALF_BLOCK
            )
          ).map((x, index) =>
            columnHelper.accessor(index.toString() + periodStartingPoint + 4, {
              id: (index + periodStartingPoint + 4).toString(),
              cell: (info) =>
                getCellData(info, index + periodStartingPoint + 4)?.dataType === "time"
                  ? renderCentred(getCellData(info, index + periodStartingPoint + 4)?.value)
                  : renderVolume(
                      getCellData(info, index + periodStartingPoint + 4)?.value?.toString()
                    ),
              header: () =>
                renderCentred(
                  columnNames[index + periodStartingPoint + 4]
                    ? columnNames[index + periodStartingPoint + 4]
                    : ""
                ),
              size: TABLE_COLUMN_WIDTH,
              minSize: TABLE_COLUMN_WIDTH,
            })
          ),
        }),
      ],
    });

  const columns = React.useMemo(
    () => [
      columnHelper.group({
        header: "EFA Day",
        id: "day0type",
        columns: [
          columnHelper.group({
            header: "Block",
            id: "dayblock0type",
            columns: [
              columnHelper.group({
                header: "Half-Block",
                id: "dayhblock0type",
                columns: [
                  columnHelper.accessor("type", {
                    id: "type 0",
                    cell: (info) => info.renderValue(),
                    header: () => <div>Period</div>,
                    size: TABLE_FIRST_COLUMN_WIDTH,
                    minSize: TABLE_FIRST_COLUMN_WIDTH,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      columnHelper.group({
        id: "day0",
        header: () =>
          renderLeftAligned(
            new Intl.DateTimeFormat("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }).format(subDays(new Date(data.day1Date), 1))
          ),
        footer: (props) => props.column.id,
        columns: [
          getBlock(0, 4, NUMBER_COLUMNS_TILL_BLOCK_1),
          getBlock(0, 5, NUMBER_COLUMNS_TILL_BLOCK_2),
          getBlock(0, 6, NUMBER_COLUMNS_TILL_BLOCK_3),
        ],
      }),
      columnHelper.group({
        id: "day1am",
        header: () =>
          renderLeftAligned(
            new Intl.DateTimeFormat("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }).format(new Date(data.day1Date))
          ),
        footer: (props) => props.column.id,
        columns: [
          getBlock(
            1,
            1,
            NUMBER_COLUMNS_TILL_BLOCK_4,
            data.day1Type === Day.ShortDay,
            data.day1Type === Day.LongDay
          ),
          getBlock(1, 2, NUMBER_COLUMNS_TILL_BLOCK_5 + OFFSET_AFTER_DAY_1_BLOCK_1),
          getBlock(1, 3, NUMBER_COLUMNS_TILL_BLOCK_6 + OFFSET_AFTER_DAY_1_BLOCK_1),
        ],
      }),
      columnHelper.group({
        id: "day1pm",
        header: () =>
          renderLeftAligned(
            new Intl.DateTimeFormat("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }).format(new Date(data.day1Date))
          ),
        footer: (props) => props.column.id,
        columns: [
          getBlock(1, 4, NUMBER_COLUMNS_TILL_BLOCK_7 + OFFSET_AFTER_DAY_1_BLOCK_1),
          getBlock(1, 5, NUMBER_COLUMNS_TILL_BLOCK_8 + OFFSET_AFTER_DAY_1_BLOCK_1),
          getBlock(1, 6, NUMBER_COLUMNS_TILL_BLOCK_9 + OFFSET_AFTER_DAY_1_BLOCK_1),
        ],
      }),
      columnHelper.group({
        id: "day2am",
        header: () =>
          renderLeftAligned(
            new Intl.DateTimeFormat("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }).format(addDays(new Date(data.day1Date), 1))
          ),
        footer: (props) => props.column.id,
        columns: [
          getBlock(
            2,
            1,
            NUMBER_COLUMNS_TILL_BLOCK_10 + OFFSET_AFTER_DAY_1_BLOCK_1,
            data.day2Type === Day.ShortDay,
            data.day2Type === Day.LongDay
          ),
          getBlock(2, 2, NUMBER_COLUMNS_TILL_BLOCK_11 + OFFSET_AFTER_DAY_2_BLOCK_1),
          getBlock(2, 3, NUMBER_COLUMNS_TILL_BLOCK_12 + OFFSET_AFTER_DAY_2_BLOCK_1),
        ],
      }),
      columnHelper.group({
        id: "day2pm",
        header: () =>
          renderLeftAligned(
            new Intl.DateTimeFormat("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }).format(addDays(new Date(data.day1Date), 1))
          ),
        footer: (props) => props.column.id,
        columns: [
          getBlock(2, 4, NUMBER_COLUMNS_TILL_BLOCK_13 + OFFSET_AFTER_DAY_2_BLOCK_1),
          getBlock(2, 5, NUMBER_COLUMNS_TILL_BLOCK_14 + OFFSET_AFTER_DAY_2_BLOCK_1),
          getBlock(2, 6, NUMBER_COLUMNS_TILL_BLOCK_15 + OFFSET_AFTER_DAY_2_BLOCK_1),
        ],
      }),
    ],
    [data]
  );

  const table = useReactTable<ITableData>({
    data: transformedTableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const requiresBorder = (index: number) => {
    if (
      !(
        data.day1Type === Day.ShortDay ||
        data.day2Type === Day.ShortDay ||
        data.day1Type === Day.LongDay ||
        data.day2Type === Day.LongDay
      )
    )
      return index % 4 === 0;
    if (data.day1Type === Day.ShortDay) {
      return index < NUMBER_COLUMNS_TILL_SHORT_DAY_DAY_1_CLOCK_CHANGE
        ? index % 4 === 0
        : (index + SHORT_DAY_COLUMN_OFFSET) % 4 === 0;
    }
    if (data.day2Type === Day.ShortDay) {
      return index < NUMBER_COLUMNS_TILL_SHORT_DAY_DAY_2_CLOCK_CHANGE
        ? index % 4 === 0
        : (index + SHORT_DAY_COLUMN_OFFSET) % 4 === 0;
    }
    if (data.day1Type === Day.LongDay) {
      return index < NUMBER_COLUMNS_TILL_LONG_DAY_DAY_1_CLOCK_CHANGE
        ? index % 4 === 0
        : (index + LONG_DAY_COLUMN_OFFSET) % 4 === 0;
    }
    if (data.day2Type === Day.LongDay) {
      return index < NUMBER_COLUMNS_TILL_LONG_DAY_DAY_2_CLOCK_CHANGE
        ? index % 4 === 0
        : (index + LONG_DAY_COLUMN_OFFSET) % 4 === 0;
    }
  };

  return (
    <PageWrapper pageTitle="Position Management" data-testid="PositionManagement">
      {isLoading && !hasloadedBefore ? (
        <LoadingIndicator />
      ) : (
        positions &&
        positions.length > 0 && (
          <ScrollContainer id="horizontal-scroll-container" tabIndex={-1}>
            <TableOverlay
              tableStart={TABLE_FIRST_COLUMN_WIDTH}
              right={nowLineIndent}
              height={TABLE_OVERLAY_HEIGHT}
            />
            <GraphOverlay
              graphStart={GRAPH_OVERLAY_START_POSITION_PIXELS}
              right={nowLineIndent}
              height={newLineHeight}
              top={GRAPH_NOW_LINE_TOP}
            />
            <NowLine
              id="now-line"
              left={nowLineIndent}
              height={newLineHeight}
              top={GRAPH_NOW_LINE_TOP}
            />
            <GateClosureLine
              id="gate-closure-line"
              left={gateClosureLineIndent}
              height={gateLineHeight}
            />
            <PNGateLine id="pn-gate-line" left={pnGateLineIndent} height={gateLineHeight} />
            <Table size={Sizes.Medium} onScroll={() => {}}>
              <THead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TR key={headerGroup.id} data-testid="table-header-row">
                    {headerGroup.headers.map((header, index) => {
                      return (
                        <TH
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{
                            position: index === 0 ? "sticky" : "relative",
                            backgroundColor: index === 0 ? theme.background.raised : "",
                            textAlign: "left",
                            zIndex: index === 0 ? 2 : 0,
                            left: 0,
                            minWidth: header.column.columnDef?.minSize,
                            width: header.column.columnDef?.size,
                            borderRight: `${theme.border.subtle} 1px solid`,
                            height: header.depth === 2 || header.depth === 3 ? 34 : 40,
                            paddingTop: 0,
                          }}
                        >
                          {header.depth === 1 && header.index !== 0 && (
                            <ColouredHeaderUnderline index={header.index} />
                          )}
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </TH>
                      );
                    })}
                  </TR>
                ))}
              </THead>
              <TBody ref={ref}>
                {table.getRowModel().rows.map((row) => (
                  <TR key={row.id} data-testid="table-row">
                    {row.getVisibleCells().map((cell, index) => (
                      <TD
                        key={cell.id}
                        style={{
                          position: index === 0 ? "sticky" : "relative",
                          backgroundColor:
                            index === 0
                              ? theme.background.raised
                              : getBackGroundColor(cell.row.original, index),
                          zIndex: index === 0 ? 2 : 0,
                          height: 40,
                          left: 0,
                          fontWeight: cell.row.original.type === OPEN_POSITION ? "bold" : "normal",
                          borderRight: requiresBorder(index)
                            ? `${theme.border.subtle} 1px solid`
                            : "",
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TD>
                    ))}
                  </TR>
                ))}
              </TBody>
            </Table>
            <BarChartComponent
              data={transformedChartData}
              height={chartHeight}
              width={tableWidth}
              minimum={getMinimumOpenPosition()}
              maximum={getMaximumOpenPosition()}
            />
          </ScrollContainer>
        )
      )}
    </PageWrapper>
  );
};

export default PositionManagement;
