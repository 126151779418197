import axios from "axios";

import {AuthenticationService} from "../pages/Authentication/AuthenticationService";

declare global {
  interface Window {
    _env_: any;
  }
}

export const getRefreshTimeInSeconds = () => {
  if (window?._env_?.REACT_APP_REFRESH_TIME_SECONDS) {
    return window._env_.REACT_APP_REFRESH_TIME_SECONDS;
  }
  return process.env.REACT_APP_REFRESH_TIME_SECONDS;
};

export const getAPIBaseUrl = () => {
  if (window?._env_?.REACT_APP_CRATOS_API_BASE_URL) {
    return window._env_.REACT_APP_CRATOS_API_BASE_URL;
  }
  // eslint-disable-next-line no-console
  console.log(`Development API base url- ${process.env.REACT_APP_CRATOS_API_BASE_URL}`);
  return process.env.REACT_APP_CRATOS_API_BASE_URL;
};

export const getEnvironmentName = () => {
  if (window?._env_?.REACT_APP_ENVIRONMENT_NAME) {
    return window._env_.REACT_APP_ENVIRONMENT_NAME;
  }
  return process.env.REACT_APP_ENVIRONMENT_NAME;
};

export const getBuildNumber = () => {
  if (window?._env_?.REACT_APP_BUILD_NUMBER) {
    return window._env_.REACT_APP_BUILD_NUMBER;
  }
  return process.env.REACT_APP_BUILD_NUMBER;
};

export const getSignalRHubUrl = () => {
  if (window?._env_?.REACT_APP_CRATOS_SIGNALR_HUB_URL) {
    return window._env_.REACT_APP_CRATOS_SIGNALR_HUB_URL;
  }
  return process.env.REACT_APP_CRATOS_SIGNALR_HUB_URL;
};

export const getBasePath = () => {
  if (window?._env_?.REACT_APP_BASE_PATH) {
    return window._env_.REACT_APP_BASE_PATH;
  }
  return process.env.REACT_APP_BASE_PATH;
};

export const getSignalRHubEndpoint = () => {
  return `${getSignalRHubUrl()}hub/notification`;
};

export const DataAPIClient = axios.create({
  baseURL: getAPIBaseUrl(),
  headers: {
    "content-type": "application/json",
  },
});

DataAPIClient.interceptors.request.use(
  async (config: any) => {
    const {token} = await AuthenticationService.Instance.getUser();
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  (error: Error) => {
    Promise.reject(error);
  }
);
