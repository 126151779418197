import {create} from "zustand";
import {devtools, persist, createJSONStorage} from "zustand/middleware";

interface IRouteState {
  routes: string[];
  addRoutes: (route: string) => void;
  clearRoutes: () => void;
}

const useRouteStore = create<IRouteState>()(
  devtools(
    persist(
      (set) => ({
        routes: [],
        addRoutes: (route) => set((state) => ({routes: [...state.routes, route]})),
        clearRoutes: () => set({routes: []}),
      }),
      {name: "routeStore", storage: createJSONStorage(() => sessionStorage)}
    )
  )
);

export default useRouteStore;
