import {Button, Tooltip, Variants} from "@sede-x/shell-ds-react-framework";
import {memo, useEffect} from "react";
import styled from "styled-components";
import {getAPIBaseUrl} from "../../core";
import {HealthCheckResult} from "../../helpers";
import {useColourVariant, useHealthCheck} from "../../hooks";
import {PageHeadings} from "../../models";
import {StatusVariants} from "../../models/enums";
import StatusIndicator from "../StatusIndicator";
import "./HealthStatus.css";

export const StyledButton = styled(Button)<{colour: string | undefined}>`
  font-size: 14px;
  min-width: 150px;
  color: ${(props) => props.colour};
`;

const StyledContainer = styled.div`
  padding: 10px;
  border: ${({theme}) => theme.border.subtle} 1px solid;
`;

const MessageContainer = styled.div<{borderLine: boolean}>`
  display: flex;
  align-items: center;
  padding: 4px;
  border-bottom: ${(props) =>
    props.borderLine ? `${props.theme.border.subtle} 1px solid` : "none"};
`;

export const HealthStatusOverlay: React.FC<HealthCheckResult> = ({
  services,
  healthStatus,
  description,
}) => {
  if (services.length > 0) {
    return (
      <StyledContainer>
        {services.map((service, index, row) => (
          <MessageContainer key={service.name} borderLine={index < row.length - 1}>
            <StatusIndicator variant={service.healthStatus} style={{marginRight: "8px"}} />
            <div>
              <span>
                Service:&nbsp;<i>{service.name}</i>
              </span>
              <div>
                Issue:&nbsp;<i>{service.description ?? description}</i>
              </div>
            </div>
          </MessageContainer>
        ))}
      </StyledContainer>
    );
  }
  return (
    <StyledContainer>
      <MessageContainer borderLine={false}>
        <StatusIndicator variant={healthStatus} style={{marginRight: "8px"}} />
        <div>
          <div>{description}</div>
        </div>
      </MessageContainer>
    </StyledContainer>
  );
};

const HealthStatus = memo(function HealthStatus() {
  const healthResponse = useHealthCheck();
  const {colour, setVariant} = useColourVariant(healthResponse.healthStatus);

  useEffect(() => {
    setVariant(healthResponse.healthStatus);
  }, [healthResponse.healthStatus]);

  return (
    <Tooltip
      placement="bottom"
      overlay={<HealthStatusOverlay {...healthResponse} />}
      trigger={
        <StyledButton
          id="btnOpenHealthChecks"
          data-testid="btnOpenHealthChecks"
          variant={Variants.Outlined}
          colour={colour}
          onClick={() => window.open(`${getAPIBaseUrl()}healthchecks`, "_blank")}
        >
          <StatusIndicator
            variant={healthResponse.healthStatus}
            style={{
              position: "absolute",
              left: "4px",
              width: "25%",
              height: "80%",
              animation:
                healthResponse.healthStatus === StatusVariants.Warning ||
                healthResponse.healthStatus === StatusVariants.Error
                  ? `blink 1s linear infinite`
                  : "none",
            }}
          />
          {PageHeadings.Healthchecks}
        </StyledButton>
      }
    />
  );
});

export default HealthStatus;
